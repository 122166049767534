import React, { useState, useEffect } from "react";
import useAPI from "./../../hooks/api/useAPI";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {
    Accordion,
    AccordionSummary,
    Container as div,
    makeStyles,
    TextField,
    AccordionDetails,
    Typography,
    Grid,
    Button,
} from "@material-ui/core";

const FindSlots = (props) => {
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [stopDate, setStopDate] = useState(moment().format("YYYY-MM-DD"));
    const [message, setMessage] = useState("Please Wait...");
    const [slotArray, setSlotArray] = useState([]);
    const api = useAPI();
    const classes = useStyles();

    const findSlots = async () => {
        try {
            let response = await fetch(
                `${api.path}/reservations/findMultiSlots`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        userId: props.userId,
                        facilityId: props.facilityId,
                        serviceId: props.serviceId,
                        startDate: startDate,
                        stopDate: stopDate,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            if (response.message) {
                switch (response.message) {
                    // case "Closed":
                    //     setMessage(
                    //         `Service is closed on ${moment(startDate).format(
                    //             "MMM DD, YYYY"
                    //         )}. Please select a different date or service.`
                    //     );
                    //     break;
                    case "Walk In Only":
                        setMessage(
                            "This service only accepts walk in visits. Please visit the location to check in."
                        );
                        break;
                    default:
                        setMessage(response.message);
                }
                setMessage(response.message);
            } else {
                setMessage("");
                setSlotArray(response);
            }
        } catch (err) {
            alert(err.message);
        }
    };

    useEffect(() => {
        findSlots();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, stopDate, props.serviceId]);

    return (
        <div className={classes.slotContainer}>
            <div className={classes.selector}>
                <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    defaultValue={startDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <TextField
                    id="stopDate"
                    label="Stop Date"
                    type="date"
                    defaultValue={stopDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={(e) => setStopDate(e.target.value)}
                />
            </div>
            {message ? (
                <div>
                    <Typography variant="h4">{message}</Typography>
                </div>
            ) : (
                <div>
                    {slotArray.map((item, index) => (
                        <div>
                            <Typography className={classes.dateHeading} variant="h3">
                                {moment(item.date).format("MMM DD, YYYY")}
                            </Typography>
                            {item.status === "Closed" ? (
                                <Typography className={classes.statusText} variant="h4">
                                    {item.status}
                                </Typography>
                            ) : (
                                ""
                            )}
                            {item.slots.map((slot, index) => (
                                <Accordion className={classes.accordion}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-content`}
                                    >
                                        <Typography
                                            className={classes.accordionHeading}
                                        >
                                            {moment(slot.start).format("HH:mm")}{" "}
                                            -{" "}
                                            {moment(slot.stop).format("HH:mm")}
                                        </Typography>
                                        <Typography
                                            className={clsx(
                                                classes.accordionSubHeading,
                                                {
                                                    [classes.errorText]:
                                                        slot.status !== "Open",
                                                }
                                            )}
                                        >
                                            {slot.status}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    {slot.serviceName} (
                                                    <em>{slot.facilityName}</em>
                                                    )
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Occupancy:{" "}
                                                    {slot.serviceCount}
                                                </Typography>
                                                <Typography variant="body1">
                                                    Facility Occupancy:{" "}
                                                    {slot.facilityCount}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                className={
                                                    classes.buttonGridContainer
                                                }
                                            >
                                                <Button
                                                    disabled={
                                                        slot.status !== "Open"
                                                            ? true
                                                            : false
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() =>
                                                        props.handleSubmit(
                                                            props.userId,
                                                            slot.start,
                                                            slot.stop
                                                        )
                                                    }
                                                >
                                                    Reserve
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            <hr />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    slotContainer: {
        minWidth: 500,
        minHeight: 300,
    },
    selector: {
        textAlign: "center",
        display: "flex",
        justifyContent: "space-around",
        padding: theme.spacing(2, 0),
        border: "solid 1px #aaa",
        boxShadow: "1px 1px #ccc",
        borderRadius: 10,
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
    },
    accordion: {
        width: "100%",
        padding: 0,
    },
    accordionHeading: {
        fontWeight: "bold",
        flexBasis: "75%",
    },
    errorText: {
        color: theme.palette.error.main,
    },
    buttonGridContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "flex-end",
    },
    dateHeading: { textAlign: 'left' },
    statusText: { textAlign: "center", color: theme.palette.error.main },
}));

export default FindSlots;
