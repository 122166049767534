/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Container,
    CssBaseline,
    Grid,
    InputLabel,
    Select,
    FormControl,
    makeStyles,
    Typography,
    TextField,
    Button,
    Tooltip,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { validator } from "./../../../../functions/validation";
import useAuth from "./../../../../hooks/auth/useAuth";
import useAPI from "./../../../../hooks/api/useAPI";
import clsx from "clsx";

const AddNewUser = (props) => {
    const auth = useAuth();
    const classes = useStyles();
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        dodin: "",
        email: "",
        phone: "",
        affiliation: "",
        unit: "",
        base: "",
    });
    const [error, setError] = useState({
        firstName: "First Name",
        lastName: "Last Name",
        dodin: "DoD ID Number",
        email: "Email",
        phone: "Phone Number",
        affiliation: "Military Affiliation",
        unit: "Unit/Sponsors Unit",
        base: "Primary Installation",
    });
    const [affiliationList, setAffiliationList] = useState([]);
    const [baseList, setBaseList] = useState([]);
    const [disableUnit, setDisableUnit] = useState(true);
    let api = useAPI();

    useEffect(() => {
        fetch(`${api.path}/affiliation`)
            .then((res) => res.json())
            .then((res) => {
                setAffiliationList(res);
            });
        fetch(`${api.path}/base`)
            .then((res) => res.json())
            .then((res) => {
                setBaseList(res);
            });
        validate();
    }, [api]);

    const validate = async () => {
        let err = {};
        for (const key in state) {
            if (key !== "repeatPassword") {
                err[key] = await validator(key, state[key], api);
            }
        }
        setError(err);
    };

    useEffect(() => {
        if (state.affiliation && state.affiliation !== "Retired") {
            setDisableUnit(false);
        } else {
            setDisableUnit(true);
            setState({ ...state, unit: "" });
            setError({ ...error, unit: "" });
        }
        validate();
    }, [state.affiliation]);

    useEffect(() => {
        validate();
    }, [state.base]);

    const handleRegistration = async () => {
        let response = await submitRegistration();
        if (response._id) {
            props.handleClose();
        } else {
            alert(response);
        }
    };

    const handleCheckIn = async () => {
        let response = await submitRegistration();
        if (response._id) {
            props.handleCheckIn(response._id);
        } else {
            alert(response);
        }
    };

    const submitRegistration = async () => {
        await validate();
        for (const key in error) {
            if (error[key] !== "") {
                return "Please ensure all fields are filled in properly";
            }
        }
        const user = {
            email: state.email,
            dodin: state.dodin,
            profile: {
                firstName: state.firstName,
                lastName: state.lastName,
                affiliation: state.affiliation,
                phone: state.phone,
                unit: state.unit,
                base: state.base,
            },
        };
        return await auth.workerRegister(user);
    };

    return (
        <Container className={classes.innerContainer} maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography variant="h2">Add New Patron</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <TextField
                            required
                            className={classes.formField}
                            id="firstName"
                            name="firstName"
                            type="text"
                            label="First Name"
                            variant="outlined"
                            maxLength={20}
                            value={state.firstName}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    [e.target.name]: e.target.value,
                                })
                            }
                            onBlur={(e) => validate(e.target.name)}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.centerItems}>
                        {error.firstName ? (
                            <Tooltip title={error.firstName} placement="left">
                                <ErrorIcon className={classes.errorIcon} />
                            </Tooltip>
                        ) : (
                            <CheckCircleIcon className={classes.checkIcon} />
                        )}
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            required
                            className={classes.formField}
                            id="lastName"
                            name="lastName"
                            type="text"
                            label="Last Name"
                            variant="outlined"
                            maxLength={20}
                            value={state.lastName}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    [e.target.name]: e.target.value,
                                })
                            }
                            onBlur={() => validate()}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.centerItems}>
                        {error.lastName ? (
                            <Tooltip title={error.lastName} placement="left">
                                <ErrorIcon className={classes.errorIcon} />
                            </Tooltip>
                        ) : (
                            <CheckCircleIcon className={classes.checkIcon} />
                        )}
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            required
                            className={classes.formField}
                            id="email"
                            name="email"
                            type="email"
                            label="Email"
                            variant="outlined"
                            maxLength={50}
                            value={state.email}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    [e.target.name]: e.target.value,
                                })
                            }
                            onBlur={() => validate()}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.centerItems}>
                        {error.email ? (
                            <Tooltip title={error.email} placement="left">
                                <ErrorIcon className={classes.errorIcon} />
                            </Tooltip>
                        ) : (
                            <CheckCircleIcon className={classes.checkIcon} />
                        )}
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            required
                            className={classes.formField}
                            id="dodin"
                            name="dodin"
                            type="number"
                            label="DoD ID Number"
                            variant="outlined"
                            onInput={(e) => {
                                e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                )
                                    .toString()
                                    .slice(0, 10);
                            }}
                            value={state.dodin}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    [e.target.name]: e.target.value,
                                })
                            }
                            onBlur={() => validate()}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.centerItems}>
                        {error.dodin ? (
                            <Tooltip title={error.dodin} placement="left">
                                <ErrorIcon className={classes.errorIcon} />
                            </Tooltip>
                        ) : (
                            <CheckCircleIcon className={classes.checkIcon} />
                        )}
                    </Grid>
                    <Grid item xs={11}>
                        <InputMask
                            mask="(999) 999-9999"
                            // value={props.phone}
                            maskChar=" "
                            id="phone"
                            name="phone"
                            required
                            className={classes.formField}
                            label="Phone Number"
                            variant="outlined"
                            value={state.phone}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    [e.target.name]: e.target.value,
                                })
                            }
                            onBlur={() => validate()}
                        >
                            {(inputProps) => <TextField {...inputProps} />}
                        </InputMask>
                    </Grid>
                    <Grid item xs={1} className={classes.centerItems}>
                        {error.phone ? (
                            <Tooltip title={error.phone} placement="left">
                                <ErrorIcon className={classes.errorIcon} />
                            </Tooltip>
                        ) : (
                            <CheckCircleIcon className={classes.checkIcon} />
                        )}
                    </Grid>
                    <Grid item xs={11}>
                        <FormControl
                            variant="outlined"
                            className={clsx(
                                classes.formControl,
                                classes.formField
                            )}
                        >
                            <InputLabel htmlFor="affiliation">
                                Affiliation
                            </InputLabel>
                            <Select
                                native
                                required
                                label="Affiliation"
                                variant="outlined"
                                value={state.affiliation}
                                inputProps={{
                                    name: "affiliation",
                                    id: "affiliation",
                                }}
                                onChange={async (e) => {
                                    setState({
                                        ...state,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                            >
                                <option value=""></option>
                                {affiliationList.map((item) => (
                                    <option
                                        key={`affiliation_${item._id}`}
                                        value={item.name}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} className={classes.centerItems}>
                        {error.affiliation ? (
                            <Tooltip title={error.affiliation} placement="left">
                                <ErrorIcon className={classes.errorIcon} />
                            </Tooltip>
                        ) : (
                            <CheckCircleIcon className={classes.checkIcon} />
                        )}
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            required={!disableUnit}
                            disabled={disableUnit}
                            className={classes.formField}
                            id="unit"
                            name="unit"
                            type="text"
                            label="Unit"
                            variant="outlined"
                            maxLength={50}
                            value={state.unit}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    [e.target.name]: e.target.value,
                                })
                            }
                            onBlur={() => validate()}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.centerItems}>
                        {!disableUnit && error.affiliation ? (
                            <Tooltip title={error.affiliation} placement="left">
                                <ErrorIcon className={classes.errorIcon} />
                            </Tooltip>
                        ) : (
                            <CheckCircleIcon className={classes.checkIcon} />
                        )}
                    </Grid>
                    <Grid item xs={11}>
                        <FormControl
                            variant="outlined"
                            className={clsx(
                                classes.formControl,
                                classes.formField
                            )}
                        >
                            <InputLabel htmlFor="base">
                                Primary Military Installation
                            </InputLabel>
                            <Select
                                native
                                required
                                label="Primary Military Installation"
                                variant="outlined"
                                value={state.base}
                                inputProps={{
                                    name: "base",
                                    id: "base",
                                }}
                                onChange={async (e) => {
                                    setState({
                                        ...state,
                                        [e.target.name]: e.target.value,
                                    });
                                }}
                            >
                                <option value=""></option>
                                {baseList.map((item) => (
                                    <option
                                        key={`base_${item._id}`}
                                        value={item._id}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} className={classes.centerItems}>
                        {error.base ? (
                            <Tooltip title={error.base} placement="left">
                                <ErrorIcon className={classes.errorIcon} />
                            </Tooltip>
                        ) : (
                            <CheckCircleIcon className={classes.checkIcon} />
                        )}
                    </Grid>
                </Grid>
                <div className={classes.footerButtons}>
                    <Button
                        fullWidth
                        className={classes.footerButton}
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.footerButton}
                        fullWidth
                        onClick={handleRegistration}
                    >
                        Register
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.footerButton}
                        fullWidth
                        onClick={handleCheckIn}
                    >
                        Register & Check In
                    </Button>
                </div>
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    checkIcon: {
        color: theme.palette.success.dark,
        marginRight: theme.spacing(1),
    },
    errorIcon: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(4, 0, 0),
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
    },
    showButton: {
        float: "right",
    },
    validationText: {
        display: "flex",
        alignItems: "center",
    },
    formField: {
        width: "100%",
    },
    footerButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    footerButton: {
        margin: theme.spacing(2),
    },
    centerItems: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    innerContainer: {
        height: "100%",
    },
}));

export default AddNewUser;
