import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "./../../hooks/auth/useAuth";
import { Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Home = () => {
    let auth = useAuth();
    const classes = useStyles()
    const [disclaimer, setDisclaimer] = useState(
        sessionStorage.getItem("disclaimer") === "false" ? false : true || true
    );

    const clearDisclaimer = () => {
        setDisclaimer(false);
        sessionStorage.setItem("disclaimer", false);
    };

    const disclaimerMessage = (
        <div>
            <Typography gutterBottom={true} className={classes.para}>
                You are entering an application developed for the United States
                Space Force's (USSF) Buckley Garrison. This application was
                developed by Delta 4 personnel for the use of the USSF
                Garrisons. The data stored here is secure but this is not a
                government hosted site. Please use this site of your own accord.
                Use of this site requires your phone number, email address, and
                Department of Defense Identification Number (DoDIN) for the
                purposes of verification or necessary contact only. No
                information will be transmitted outside of this system to any
                3rd parties outside of the Garrison without prior notification.
                No Personnally Identifiable Information (PII) will be stored on
                this site or will ever be requested as long as it is hosted
                privately. For any further questions please contact
                garrisonschedulerapp@gmail.com.
            </Typography>
            <Typography gutterBottom={true} className={classes.para}>
                This site uses cookies to store small amounts of data. This site
                does not use any third or fourth party cookies and does not
                share your data to any outside vendors. Cookies are only used to
                enhance your user experience.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={clearDisclaimer}
            >
                Acknowledge
            </Button>
        </div>
    );
    const welcomeMessage = auth.user ? (
        <div>
            <Typography variant="h2" className={classes.para}>Welcome back,<br />{auth.user}!</Typography>
        </div>
    ) : (
        <div>
            <Typography variant="h2" className={classes.para}>Welcome!</Typography>
            <Typography variant="body1" className={classes.para}>
                The Garrison Schedule is your home to schedule activities on garrison. If there's a facility that you would like to see on here from your garrison, let them know about this application or fill out a comment card!
            </Typography>
            <Typography variant="body1" className={classes.para}>
                If this is your first time here, head on over to the <Link to="/register">registration page</Link> to create your account. If you were registered with a service on base, please check your email for a message from us. Don't forget to check your junk mail if you don't find it in your inbox!
            </Typography>
        </div>
    );

    return (
        <Container component="main" maxWidth="sm" className={classes.container}>
            {disclaimer === false ? welcomeMessage : disclaimerMessage}
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        height: '100%',
        width: '100%',
    },
    para: {
        marginBottom: theme.spacing(2)
    }
}));

export default Home;
