import React, { useEffect, useState } from "react";
import useAPI from "./../../../hooks/api/useAPI";
import {
    Container,
    Paper,
    Select,
    TextField,
    FormControl,
    InputLabel,
    makeStyles,
    Grid,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import FindSlots from "./../../common/FindSlots";
import { Typography } from "@material-ui/core";

const Scheduler = (props) => {
    const api = useAPI();
    const classes = useStyles();

    const [base, setBase] = useState("Select");
    const [baseList, setBaseList] = useState([]);
    const [facility, setFacility] = useState("Select");
    const [facilityList, setFacilityList] = useState([]);
    const [service, setService] = useState("Select");
    const [serviceList, setServiceList] = useState([]);
    // const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    // const [stopDate, setStopDate] = useState(moment().format("YYYY-MM-DD"));

    const getDefaultBase = async () => {
        try {
            let response = await fetch(`${api.path}/users/my/profile/base`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setBase(response.profile.base);
        } catch (err) {
            alert(err.message);
        }
    };

    const getBaseList = async () => {
        try {
            let response = await fetch(`${api.path}/base`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            if (response) setBaseList(response);
        } catch (err) {
            alert(err.message);
        }
    };

    const getFacilityList = async () => {
        try {
            let response = await fetch(`${api.path}/facility`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setFacilityList(response);
        } catch (err) {
            alert(err.message);
        }
    };

    useEffect(() => {
        getDefaultBase();
        getBaseList();
        getFacilityList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (facility !== "Select") {
            let index = facilityList.findIndex((item) => item._id === facility);
            setServiceList(facilityList[index].service);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facility]);

    const handleCreateReservation = async (user, start, stop) => {
        try {
            let index = serviceList.findIndex((item) => item._id === service);
            console.log("start", start);
            console.log("stop", stop);

            const reservation = {
                facility: facility,
                service: service,
                status: "ok",
                subFromFacility: serviceList[index].subGuestsFromFacility,
                start: moment(start),
                stop: moment(stop),
            };
            let response = await fetch(`${api.path}/reservations/`, {
                method: "POST",
                body: JSON.stringify(reservation),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            alert("Reservation created");
        } catch (err) {
            alert(err.message);
        }
    };

    return (
        <Container maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <Paper className={classes.paperContainer}>
                        <Grid container spacing={1}>
                            {/* <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="startDate"
                                    label="Start Date"
                                    type="date"
                                    variant="outlined"
                                    className={classes.textField}
                                    defaultValue={startDate}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) =>
                                        setStartDate(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="stopDate"
                                    label="Stop Date"
                                    type="date"
                                    variant="outlined"
                                    className={classes.textField}
                                    defaultValue={stopDate}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) =>
                                        setStopDate(e.target.value)
                                    }
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="base">Base</InputLabel>
                                    <Select
                                        native
                                        fullWidth
                                        label="Base"
                                        variant="outlined"
                                        value={base}
                                        inputProps={{
                                            name: "base",
                                            id: "base",
                                        }}
                                        onChange={async (e) => {
                                            setBase(e.target.value);
                                        }}
                                    >
                                        <option value="Select">Select</option>
                                        {baseList.map((item) => (
                                            <option
                                                key={`base_${item._id}`}
                                                value={item._id}
                                            >
                                                {item.name}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {base !== "Select" ? (
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="facility">
                                            Facility
                                        </InputLabel>
                                        <Select
                                            native
                                            fullWidth
                                            label="Facility"
                                            variant="outlined"
                                            value={facility}
                                            inputProps={{
                                                name: "facility",
                                                id: "facility",
                                            }}
                                            onChange={async (e) => {
                                                setFacility(e.target.value);
                                            }}
                                        >
                                            <option value="Select">
                                                Select
                                            </option>
                                            {facilityList
                                                .filter(
                                                    (facility) =>
                                                        facility.base._id ===
                                                        base
                                                )
                                                .map((item) => (
                                                    <option
                                                        key={`facility_${item._id}`}
                                                        value={item._id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {facility !== "Select" ? (
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="service">
                                            Service
                                        </InputLabel>
                                        <Select
                                            native
                                            label="Service"
                                            variant="outlined"
                                            value={service}
                                            inputProps={{
                                                name: "service",
                                                id: "service",
                                            }}
                                            onChange={async (e) => {
                                                setService(e.target.value);
                                            }}
                                        >
                                            <option value="Select">
                                                Select
                                            </option>
                                            {serviceList.map((item) => (
                                                <option
                                                    key={`service_${item._id}`}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Paper className={classes.slotContainer}>
                        {/* {moment(startDate) <= moment(stopDate) && service ? <FindSlots selectedDate={startDate} facilityId={facility} serviceId={service}/> : moment(startDate) > moment(stopDate) ? "Please ensure that the Start Date is before Stop Date." : "Please select a service to view available reservations." } */}
                        {service !== "Select" ? (
                            <FindSlots
                                facilityId={facility}
                                serviceId={service}
                                handleSubmit={handleCreateReservation}
                            />
                        ) : (
                            <Typography variant="body1">
                                Please select a service to view available
                                reservations.
                            </Typography>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    paperContainer: {
        padding: theme.spacing(2),
    },
    slotContainer: {
        padding: theme.spacing(2),
        minHeight: "100%",
    },
}));

export default Scheduler;
