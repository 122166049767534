import {
    FormControl,
    InputLabel,
    Select,
    TextField,
    Typography,
    Grid,
    Container,
    Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useAPI from "./../../../../hooks/api/useAPI";

const FacilitySettings = (props) => {
    const api = useAPI();
    const classes = useStyles();

    const [name, setName] = useState(props.facility.name);
    const [base, setBase] = useState(props.facility.base._id);
    const [maxGuests, setMaxGuests] = useState(
        props.facility.settings.maxGuests
    );
    const [baseList, setBaseList] = useState([]);

    useEffect(() => {
        fetch(`${api.path}/base`)
            .then((res) => res.json())
            .then((res) => {
                setBaseList(res);
            });
    }, [api]);

    useEffect(() => {
        if (
            name !== props.facility.name ||
            base !== props.facility.base._id ||
            maxGuests !== props.facility.settings.maxGuests
        ) {
            props.handleChanges(true);
        } else {
            props.handleChanges(false);
        }
    }, [name, base, maxGuests, props]);

    const submitForm = async (e) => {
        e.preventDefault();
        if (props.dirty) {
            try {
                const facility = {
                    _id: props.facility._id,
                    name: name,
                    base: base,
                    settings: {
                        maxGuests: maxGuests,
                    },
                };
                let response = await fetch(`${api.path}/facility/`, {
                    method: "PATCH",
                    body: JSON.stringify(facility),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    const statusCode = response.status;
                    response = await response.json();
                    throw new Error(`${statusCode} - ${response.message}`);
                }
                response = await response.json();
                if (response.nModified !== 1) {
                    throw new Error(`Entry not updated.`);
                } else {
                    alert("Settings Updated!");
                    props.handleUpdate();
                }
            } catch (err) {
                alert(err.message);
                return;
            }
        }
    };

    return (
        <div>
            <Typography variant="h2">Edit Facility Settings</Typography>
            <form className={classes.form} onSubmit={submitForm} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="name"
                            label="Facility Name"
                            variant="outlined"
                            value={name}
                            onChange={(e) =>
                                setName(e.target.value.slice(0, 30))
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="base" shrink>
                                Base
                            </InputLabel>
                            <Select
                                native
                                label="Base"
                                value={base}
                                inputProps={{
                                    name: "base",
                                    id: "base",
                                }}
                                onChange={(e) => setBase(e.target.value)}
                            >
                                {" "}
                                <option value=""></option>
                                {baseList.map((item) => (
                                    <option
                                        key={`base_${item._id}`}
                                        value={item._id}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="maxGuests"
                            label="Maximum Number of Guests"
                            variant="outlined"
                            type="number"
                            value={maxGuests}
                            onChange={(e) => setMaxGuests(e.target.value)}
                            fullWidth
                            helperText="Set to zero (0) for unlimited"
                        />
                    </Grid>
                </Grid>
                <Container className={classes.footerButtons}>
                    <Button
                        fullWidth
                        className={classes.footerButton}
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.footerButton}
                        fullWidth
                        disabled={!props.dirty}
                        type="submit"
                    >
                        Save
                    </Button>
                </Container>
            </form>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    footerButtons: {
        position: "absolute",
        left: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    footerButton: {
        margin: theme.spacing(2),
    },
}));

export default FacilitySettings;
