import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Container,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Paper,
    Popover,
    TextField,
    Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useAPI from "./../../../../hooks/api/useAPI";

const ViewReservations = (props) => {
    let classes = useStyles();
    let api = useAPI();
    const [expanded, setExpanded] = useState(false);
    const [reservations, setReservations] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popover, setPopover] = useState("");
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [stopDate, setStopDate] = useState(moment().format("YYYY-MM-DD"));

    const getReservations = async () => {
        try {
            let response = await fetch(
                `${api.path}/reservations/service/${props.serviceId}/${startDate}/${stopDate}`
            );
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setReservations(response);
        } catch (err) {
            alert("Error: " + err.message);
        }
    };

    useEffect(() => {
        getReservations();
    }, [startDate, stopDate]);

    const popoverOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setPopover(`popover-${id}`);
    };

    const popoverClose = () => {
        setAnchorEl(null);
        setPopover("");
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const resList = reservations.filter((res) => res.cancelled !== true).map((res) => {
        const stopDTG = moment(res.stop);
        const startDTG = moment(res.start);
        const startTime = startDTG.format("HH:mm");
        const stopTime = stopDTG.format("HH:mm");
        return (
            <Accordion
                expanded={expanded === `panel${res._id}`}
                onChange={handleChange(`panel${res._id}`)}
                key={`panel${res._id}`}
                className={classes.accordion}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${res._id}bh-content`}
                    id={`panel${res._id}bh-header`}
                >
                    <Typography variant="body2" className={classes.dateTime}>
                        {moment(res.start).format("MM-DD-YYYY HH:mm")}
                    </Typography>
                    <Typography
                        variant="body2"
                        className={classes.name}
                    >{`${res.user.profile.firstName} ${res.user.profile.lastName}`}</Typography>
                </AccordionSummary>
                <AccordionDetails id={`panel${res._id}bh-details`}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Start</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {startTime}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Stop</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {stopTime}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Checked In</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {res.checkedIn
                                            ? moment(res.checkedIn).format(
                                                  "HH:mm"
                                              )
                                            : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Checked Out</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {res.checkedOut
                                            ? moment(res.checkedOut).format(
                                                  "HH:mm"
                                              )
                                            : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Visit Duration</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {res.checkedIn && res.checkedOut
                                            ? `${moment(res.checkedOut).diff(
                                                  moment(res.checkedIn),
                                                  "minutes"
                                              )} minutes`
                                            : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                className={classes.optionButton}
                                onClick={(e) => popoverOpen(e, res._id)}
                            >
                                Options
                            </Button>
                            <Popover
                                id={`popover-${res._id}`}
                                open={popover === `popover-${res._id}`}
                                anchorEl={anchorEl}
                                onClose={popoverClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <Container className={classes.optionContainer}>
                                    <Button
                                        onClick={() =>
                                            props.handleOpenPopup(
                                                "viewProfile",
                                                res.user._id
                                            )
                                        }
                                        fullWidth
                                    >
                                        View Profile
                                    </Button>
                                    {/* <Button
                                        onClick={() =>
                                            props.handleLateCheckIn(res._id)
                                        }
                                        fullWidth
                                    >
                                        Cancel Reservation
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            props.handleNoShow(res._id)
                                        }
                                        fullWidth
                                    >
                                        Update Reservation
                                    </Button> */}
                                </Container>
                            </Popover>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    });

    return (
        <>
            <div className={classes.container}>
                <DialogTitle id="dialog-viewReservations">
                    View Reservations
                </DialogTitle>
                <DialogContent>
                    <Paper className={classes.formContainer}>
                        <TextField
                            id="startDate"
                            label="Start Date"
                            type="date"
                            variant="outlined"
                            defaultValue={startDate}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <TextField
                            id="stopDate"
                            label="Stop Date"
                            type="date"
                            variant="outlined"
                            defaultValue={stopDate}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => setStopDate(e.target.value)}
                        />
                    </Paper>
                    {reservations.length > 0
                        ? resList
                        : "No reservations in the set date range"}
                </DialogContent>
            </div>
            <DialogActions>
                <Button onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    dateTime: {
        fontWeight: "bold",
        flexBasis: "50%",
        flexShrink: 0,
    },
    optionButton: {
        width: 175,
        float: "right",
    },
    optionContainer: {
        display: "flex",
        flexDirection: "column",
    },
    container: {
        width: 600,
        minHeight: 400,
    },
    formContainer: {
        margin: theme.spacing(0, 0, 3, 0),
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "space-evenly",
    },
}));

export default ViewReservations;
