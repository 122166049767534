import {
    Typography,
    Container,
    CssBaseline,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Drawer,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useAPI from "./../../../hooks/api/useAPI";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FacilitySettings from "./flyouts/FacilitySettings";
import { FastForwardOutlined } from "@material-ui/icons";
import Administrators from "./flyouts/Administrators";
import Employees from "./flyouts/Employees";
import ServiceSettings from "./flyouts/ServiceSettings";
import ServiceHours from "./flyouts/ServiceHours";
import AddService from "./flyouts/AddService";

const AdminDisplay = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [facility, setFacility] = useState({});
    const [flyoutStatus, setFlyoutStatus] = useState(false);
    const [flyoutForm, setFlyoutForm] = useState("");
    const [flyoutFormIndex, setFlyoutFormIndex] = useState("");
    const [flyoutDirty, setFlyoutDirty] = useState(false);

    const getFacility = async (id) => {
        try {
            let response = await fetch(`${api.path}/facility/admin/${id}`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setFacility(response);
        } catch (err) {
            alert("Error: " + err.message);
        }
    };

    const handleEditClick = (type, index = 0) => {
        setFlyoutStatus(true);
        setFlyoutForm(type);
        setFlyoutFormIndex(index);
    };

    const handleFlyoutClose = () => {
        if (flyoutDirty) {
            let response = window.confirm(
                "You have unsaved changes. Would you like to cancel your changes and close this window?"
            );
            if (response) {
                setFlyoutStatus(false);
                setFlyoutForm("");
            }
        } else {
            setFlyoutStatus(false);
            setFlyoutForm("");
        }
    };

    const handleFlyoutFormChanges = (value) => {
        setFlyoutDirty(value);
    };

    const handleFacilityUpdated = () => {
        setFlyoutDirty(false);
        setFlyoutStatus(false);
        setFlyoutForm("");
        props.getFacility();
    };

    useEffect(() => {
        getFacility(props.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const display = facility.settings ? (
        <>
            <Typography variant="h3">{facility.name} Administration</Typography>
            <Grid container spacing={2}>
                <Grid item md={4} sm={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <Typography variant="h4">Settings</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => handleEditClick("settings")}
                            >
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant="body1">Name</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">
                                {facility.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">Base</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">
                                {facility.base.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">Max Guests</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1">
                                {facility.settings.maxGuests > 0
                                    ? facility.settings.maxGuests
                                    : "Unlimited"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item md={4} xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <Typography variant="h4">Administrators</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() =>
                                    handleEditClick("administrators")
                                }
                            >
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                    {facility.administrators.map((worker, index) => (
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aira-controls={`panel${index}-header`}
                                id={`panel${index}-header`}
                            >
                                <Typography className={classes.accordHeading}>
                                    {worker.profile.firstName}{" "}
                                    {worker.profile.lastName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            {worker.profile.phone}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{worker.email}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>

                <Grid item md={4} xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <Typography variant="h4">Employees</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => handleEditClick("employees")}
                            >
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                    {facility.employees.map((worker, index) => (
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aira-controls={`panel${index}-header`}
                                id={`panel${index}-header`}
                            >
                                <Typography className={classes.accordHeading}>
                                    {worker.profile.firstName}{" "}
                                    {worker.profile.lastName}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            {worker.profile.phone}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{worker.email}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={9}>
                            <Typography variant="h4">Services</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={() => handleEditClick("add-service")}
                            >
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                    {facility.service.map((service, index) => (
                        <Accordion className={classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aira-controls={`panel${index}-header`}
                                id={`panel${index}-header`}
                            >
                                <Typography className={classes.accordHeading}>
                                    {service.name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={1}>
                                    <Grid item md={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={9}>
                                                <Typography variant="h4">
                                                    Settings
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    onClick={() =>
                                                        handleEditClick(
                                                            "service-settings",
                                                            index
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Name
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {service.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Description
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {service.description}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Max Guests
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {service.maxGuests}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Self Check-In
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {service.selfCheckIn
                                                        ? "Yes"
                                                        : "No"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Self Check-Out
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {service.selfCheckOut
                                                        ? "Yes"
                                                        : "No"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Subtract Guests from
                                                    Facility
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {service.subGuestsFromFacility
                                                        ? "Yes"
                                                        : "No"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Walk In Only
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {service.walkInOnly
                                                        ? "Yes"
                                                        : "No"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Groups Only
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {service.groupsOnly
                                                        ? "Yes"
                                                        : "No"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Block Time Only
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {service.blockTimeOnly
                                                        ? "Yes"
                                                        : "No"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Reservation Duration
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {
                                                        service.reservationDuration
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Reservation Slot Interval
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    {
                                                        service.reservationInterval
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={9}>
                                                <Typography variant="h4">
                                                    Hours
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    onClick={() =>
                                                        handleEditClick(
                                                            "service-hours",
                                                            index
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Sunday
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {service.hours[0].closed ? (
                                                    <Typography variant="body1">
                                                        CLOSED
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1">
                                                        {
                                                            service.hours[0]
                                                                .openTime
                                                        }{" "}
                                                        &mdash;
                                                        {
                                                            service.hours[0]
                                                                .closeTime
                                                        }
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Monday
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {service.hours[1].closed ? (
                                                    <Typography variant="body1">
                                                        CLOSED
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1">
                                                        {
                                                            service.hours[1]
                                                                .openTime
                                                        }{" "}
                                                        &mdash;
                                                        {
                                                            service.hours[1]
                                                                .closeTime
                                                        }
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Tuesday
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {service.hours[2].closed ? (
                                                    <Typography variant="body1">
                                                        CLOSED
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1">
                                                        {
                                                            service.hours[2]
                                                                .openTime
                                                        }{" "}
                                                        &mdash;
                                                        {
                                                            service.hours[2]
                                                                .closeTime
                                                        }
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Wednesday
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {service.hours[3].closed ? (
                                                    <Typography variant="body1">
                                                        CLOSED
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1">
                                                        {
                                                            service.hours[3]
                                                                .openTime
                                                        }{" "}
                                                        &mdash;
                                                        {
                                                            service.hours[3]
                                                                .closeTime
                                                        }
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Thursday
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {service.hours[4].closed ? (
                                                    <Typography variant="body1">
                                                        CLOSED
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1">
                                                        {
                                                            service.hours[4]
                                                                .openTime
                                                        }{" "}
                                                        &mdash;
                                                        {
                                                            service.hours[4]
                                                                .closeTime
                                                        }
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Friday
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {service.hours[5].closed ? (
                                                    <Typography variant="body1">
                                                        CLOSED
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1">
                                                        {
                                                            service.hours[5]
                                                                .openTime
                                                        }{" "}
                                                        &mdash;
                                                        {
                                                            service.hours[5]
                                                                .closeTime
                                                        }
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Saturday
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {service.hours[6].closed ? (
                                                    <Typography variant="body1">
                                                        CLOSED
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body1">
                                                        {
                                                            service.hours[6]
                                                                .openTime
                                                        }{" "}
                                                        &mdash;
                                                        {
                                                            service.hours[6]
                                                                .closeTime
                                                        }
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Grid>
            </Grid>
        </>
    ) : (
        <Typography>Please Wait</Typography>
    );

    const flyoutDisplay = () => {
        switch (flyoutForm) {
            case "settings":
                return (
                    <FacilitySettings
                        facility={facility}
                        handleChanges={handleFlyoutFormChanges}
                        handleClose={handleFlyoutClose}
                        dirty={flyoutDirty}
                        handleUpdate={handleFacilityUpdated}
                    />
                );
            case "administrators":
                return (
                    <Administrators
                        userIds={facility.administrators.map(
                            (user) => user._id
                        )}
                        facilityId={facility._id}
                        handleChanges={handleFlyoutFormChanges}
                        handleClose={handleFlyoutClose}
                        dirty={flyoutDirty}
                        handleUpdate={handleFacilityUpdated}
                    />
                );
            case "employees":
                return (
                    <Employees
                        userIds={facility.employees.map((user) => user._id)}
                        facilityId={facility._id}
                        handleChanges={handleFlyoutFormChanges}
                        handleClose={handleFlyoutClose}
                        dirty={flyoutDirty}
                        handleUpdate={handleFacilityUpdated}
                    />
                );
            case "service-settings":
                return (
                    <ServiceSettings
                        service={facility.service[flyoutFormIndex]}
                        facilityId={facility._id}
                        handleChanges={handleFlyoutFormChanges}
                        handleClose={handleFlyoutClose}
                        dirty={flyoutDirty}
                        handleUpdate={handleFacilityUpdated}
                    />
                );
            case "service-hours":
                return (
                    <ServiceHours
                        hours={facility.service[flyoutFormIndex].hours}
                        facilityId={facility._id}
                        serviceId={facility.service[flyoutFormIndex]._id}
                        serviceName={facility.service[flyoutFormIndex].name}
                        handleChanges={handleFlyoutFormChanges}
                        handleClose={handleFlyoutClose}
                        dirty={flyoutDirty}
                        handleUpdate={handleFacilityUpdated}
                    />
                );
            case "add-service":
                return (
                    <AddService
                        facilityId={facility._id}
                        handleChanges={handleFlyoutFormChanges}
                        handleClose={handleFlyoutClose}
                        dirty={flyoutDirty}
                        handleUpdate={handleFacilityUpdated}
                    />
                );
            default:
                return;
        }
    };

    return (
        <Container>
            <CssBaseline />
            <div className={classes.paper}>{display}</div>
            <Drawer
                anchor="right"
                open={flyoutStatus}
                onClose={handleFlyoutClose}
            >
                <Container className={classes.flyoutContainer}>
                    {flyoutDisplay()}
                </Container>
            </Drawer>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    flyoutContainer: {
        width: 500,
    },
}));

export default AdminDisplay;
