import React, { useState, useEffect, useCallback } from "react";
import useAPI from "./../../../../hooks/api/useAPI";
import {
    Typography,
    CssBaseline,
    Container,
    makeStyles,
    Grid,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Popover,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const LookupUser = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchText, setSearchText] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [popover, setPopover] = useState("");

    const popoverOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setPopover(`popover-${id}`);
    };

    const popoverClose = () => {
        setAnchorEl(null);
        setPopover("");
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const filterUsers = useCallback(async () => {
        if (searchText !== "") {
            try {
                let response = await fetch(
                    `${api.path}/users/search?value=${searchText}`
                );
                if (!response.ok) {
                    const statusCode = response.status;
                    response = await response.json();
                    throw new Error(`${statusCode} - ${response.message}`);
                }
                response = await response.json();
                setUsers(response);
            } catch (err) {
                alert(err.message);
            }
        } else {
            setUsers([]);
        }
    }, [api.path, searchText]);

    useEffect(() => {
        filterUsers();
    }, [filterUsers]);

    const handleVerify = async (id) => {
        try {
            let response = await fetch(`${api.path}/users/user`, {
                method: "PATCH",
                body: JSON.stringify({
                    _id: id,
                    CACVerified: true,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            filterUsers();
        } catch (err) {
            alert(err.message);
        }
    };

    const resultDisplay = users.map((user, index) => (
        <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            key={`panel${index}`}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
            >
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <Typography
                            variant="body1"
                            className={classes.accordionTitle}
                        >
                            {`${user.profile.firstName} ${user.profile.lastName}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="body2"
                            className={classes.accordionSubtitle}
                        >
                            {user.dodin}
                        </Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails id={`panel${index}bh-details`}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body1">ID Verified</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {user.CACVerified ? (
                            <Typography variant="body1">Verified</Typography>
                        ) : (
                            <Typography
                                variant="body1"
                                className={classes.warning}
                            >
                                Not Verified
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        {user.CACVerified ? (
                            ""
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleVerify(user._id)}
                            >
                                Verify ID
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) =>
                                popoverOpen(
                                    e,
                                    user._id,
                                    `${user.profile.firstName} ${user.profile.lastName}`
                                )
                            }
                            fullWidth
                        >
                            Options
                        </Button>
                        <Popover
                            id={`popover-${user._id}`}
                            open={popover === `popover-${user._id}`}
                            anchorEl={anchorEl}
                            onClose={popoverClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <Container className={classes.optionContainer}>
                                <Button
                                    onClick={() =>
                                        props.handleOpenPopup(
                                            "createReservation",
                                            user._id
                                        )
                                    }
                                    fullWidth
                                >
                                    Create Reservation
                                </Button>
                                <Button
                                    onClick={() =>
                                        props.handleOpenPopup(
                                            "viewProfile",
                                            user._id
                                        )
                                    }
                                    fullWidth
                                >
                                    View Profile
                                </Button>
                            </Container>
                        </Popover>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => props.handleCheckIn(user._id)}
                        >
                            Check In
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    ));

    return (
        <Container>
            <CssBaseline />
            <div className={classes.paper}>
                <Typography variant="h2">Lookup Patron</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            id="search"
                            label="Search Name or DoDIN"
                            variant="outlined"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">Results</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {resultDisplay}
                    </Grid>
                </Grid>
                <Container className={classes.footerButtons}>
                    <Button
                        fullWidth
                        className={classes.footerButton}
                        onClick={props.handleClose}
                    >
                        Close
                    </Button>
                </Container>
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    footerButtons: {
        position: "absolute",
        left: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    footerButton: {
        margin: theme.spacing(2),
    },
    optionContainer: {
        display: "flex",
        flexDirection: "column",
    },
}));

export default LookupUser;
