import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./screens/public/Home";
import Login from "./screens/public/Login";
import Register from "./screens/public/register/Register";
import Registered from "./screens/public/register/Registered";
import Verify from "./screens/public/verify/Verify";
import ProvideAuth from "./hooks/auth/ProvideAuth";
import Navigation from "./screens/public/Navigation";
import ProvideAPI from "./hooks/api/ProvideAPI";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./config/theme";
import UserRoute from "./hooks/auth/UserRoute";
import AdminRoute from "./hooks/auth/AdminRoute";
import ResendCode from "./screens/public/verify/ResendCode";
import ResetPassword from "./screens/public/password/ResetPassword";
import SendLink from "./screens/public/password/SendLink";
import Profile from "./screens/private/my/Profile";
import Dashboard from "./screens/private/service/Dashboard";
import Reservations from "./screens/private/my/Reservations";
import About from "./screens/public/About";
import Scheduler from "./screens/public/scheduler/Scheduler";

const App = () => {
    return (
        <ProvideAPI>
            <ProvideAuth>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Router>
                        <div className="App">
                            <Navigation />
                            <main>
                                <Switch>
                                    <UserRoute path="/scheduler">
                                        <Scheduler />
                                    </UserRoute>
                                    <AdminRoute path="/dashboard">
                                        <Dashboard />
                                    </AdminRoute>
                                    <UserRoute path="/my/profile">
                                        <Profile />
                                    </UserRoute>
                                    <UserRoute path="/my/reservations">
                                        <Reservations />
                                    </UserRoute>
                                    <Route path="/login">
                                        <Login />
                                    </Route>
                                    <Route path="/register">
                                        <Register />
                                    </Route>
                                    <Route path="/registered">
                                        <Registered />
                                    </Route>
                                    <Route path="/verify">
                                        <Verify />
                                    </Route>
                                    <Route path="/send-verify">
                                        <ResendCode />
                                    </Route>
                                    <Route path="/reset-password">
                                        <ResetPassword />
                                    </Route>
                                    <Route path="/send-reset">
                                        <SendLink />
                                    </Route>
                                    <Route path="/about">
                                        <About />
                                    </Route>
                                    <Route path="/">
                                        <Home />
                                    </Route>
                                </Switch>
                            </main>
                        </div>
                    </Router>
                </ThemeProvider>
            </ProvideAuth>
        </ProvideAPI>
    );
};

export default App;
