/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Container,
    CssBaseline,
    Grid,
    InputLabel,
    Select,
    FormControl,
    makeStyles,
    Typography,
    TextField,
    Button,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import { validator } from "../../../functions/validation";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import useAuth from "./../../../hooks/auth/useAuth";
import useAPI from "./../../../hooks/api/useAPI";

const Register = (props) => {
    const auth = useAuth();
    const classes = useStyles();
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        dodin: "",
        email: "",
        phone: "",
        affiliation: "",
        unit: "",
        base: "",
        password: "",
        repeatPassword: "",
    });
    const [error, setError] = useState({
        firstName: "First Name",
        lastName: "Last Name",
        dodin: "DoD ID Number",
        email: "Email",
        phone: "Phone Number",
        affiliation: "Military Affiliation",
        unit: "Unit/Sponsors Unit",
        base: "Primary Installation",
        password:
            "Password must contain: 6-16 characters, 1 capital letter, 1 lowercase letter, 1 number, 1 special character",
    });
    const [repeatError, setRepeatError] = useState("Passwords must match");
    const [affiliationList, setAffiliationList] = useState([]);
    const [baseList, setBaseList] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [disableUnit, setDisableUnit] = useState(true);
    let api = useAPI();
    let history = useHistory();

    useEffect(() => {
        fetch(`${api.path}/affiliation`)
            .then((res) => res.json())
            .then((res) => {
                setAffiliationList(res);
            });
        fetch(`${api.path}/base`)
            .then((res) => res.json())
            .then((res) => {
                setBaseList(res);
            });
        validate();
    }, [api]);

    const validate = async () => {
        let err = {};
        for (const key in state) {
            if (key !== "repeatPassword") {
                err[key] = await validator(key, state[key], api);
            }
        }
        setError(err);
    };

    useEffect(() => {
        if (state.affiliation && state.affiliation !== "Retired") {
            setDisableUnit(false);
        } else {
            setDisableUnit(true);
            setState({ ...state, unit: "" });
            setError({ ...error, unit: "" });
        }
        validate();
    }, [state.affiliation]);

    useEffect(() => {
        validate();
    }, [state.base]);

    useEffect(() => {
        if (state.repeatPassword === state.password) {
            setRepeatError("");
        } else {
            setRepeatError("Passwords must match");
        }
    }, [state.password, state.repeatPassword]);

    const submitRegistration = async (e) => {
        e.preventDefault();
        await validate();
        for (const key in error) {
            if (error[key] !== "") {
                alert("Please ensure all fields are filled in properly");
                return;
            }
        }
        if (repeatError) {
            alert("Please ensure all fields are filled in properly");
            return;
        }
        try {
            const user = {
                email: state.email,
                dodin: state.dodin,
                password: state.password,
                profile: {
                    firstName: state.firstName,
                    lastName: state.lastName,
                    affiliation: state.affiliation,
                    phone: state.phone,
                    unit: state.unit,
                    base: state.base,
                },
            };
            let response = await auth.selfRegister(user);
            if (response._id) {
                history.replace("/registered");
            } else {
                alert(response);
            }
        } catch (err) {
            alert(err.message);
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h2">
                    Registration
                </Typography>
                <form
                    className={classes.form}
                    onSubmit={submitRegistration}
                    noValidate
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        label="First Name"
                                        variant="outlined"
                                        maxLength={20}
                                        value={state.firstName}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        onBlur={(e) => validate(e.target.name)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        label="Last Name"
                                        variant="outlined"
                                        maxLength={20}
                                        value={state.lastName}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        onBlur={() => validate()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        name="email"
                                        type="email"
                                        label="Email"
                                        variant="outlined"
                                        maxLength={50}
                                        value={state.email}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        onBlur={() => validate()}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="dodin"
                                        name="dodin"
                                        type="number"
                                        label="DoD ID Number"
                                        variant="outlined"
                                        onInput={(e) => {
                                            e.target.value = Math.max(
                                                0,
                                                parseInt(e.target.value)
                                            )
                                                .toString()
                                                .slice(0, 10);
                                        }}
                                        value={state.dodin}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        onBlur={() => validate()}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        // value={props.phone}
                                        maskChar=" "
                                        id="phone"
                                        name="phone"
                                        required
                                        fullWidth
                                        label="Phone Number"
                                        variant="outlined"
                                        value={state.phone}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        onBlur={() => validate()}
                                    >
                                        {(inputProps) => (
                                            <TextField {...inputProps} />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="affiliation">
                                            Affiliation
                                        </InputLabel>
                                        <Select
                                            native
                                            required
                                            label="Affiliation"
                                            variant="outlined"
                                            value={state.affiliation}
                                            inputProps={{
                                                name: "affiliation",
                                                id: "affiliation",
                                            }}
                                            onChange={async (e) => {
                                                setState({
                                                    ...state,
                                                    [e.target.name]:
                                                        e.target.value,
                                                });
                                            }}
                                        >
                                            <option value=""></option>
                                            {affiliationList.map((item) => (
                                                <option
                                                    key={`affiliation_${item._id}`}
                                                    value={item.name}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required={!disableUnit}
                                        disabled={disableUnit}
                                        fullWidth
                                        id="unit"
                                        name="unit"
                                        type="text"
                                        label="Unit"
                                        variant="outlined"
                                        maxLength={50}
                                        value={state.unit}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        onBlur={() => validate()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="base">
                                            Primary Military Installation
                                        </InputLabel>
                                        <Select
                                            native
                                            required
                                            label="Primary Military Installation"
                                            variant="outlined"
                                            value={state.base}
                                            inputProps={{
                                                name: "base",
                                                id: "base",
                                            }}
                                            onChange={async (e) => {
                                                setState({
                                                    ...state,
                                                    [e.target.name]:
                                                        e.target.value,
                                                });
                                            }}
                                        >
                                            <option value=""></option>
                                            {baseList.map((item) => (
                                                <option
                                                    key={`base_${item._id}`}
                                                    value={item._id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        id="password"
                                        name="password"
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        label="Password"
                                        variant="outlined"
                                        maxLength={50}
                                        value={state.password}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                        onBlur={() => validate()}
                                    />
                                    <Button
                                        className={classes.showButton}
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword
                                            ? "Hide Password"
                                            : "Show Password"}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        id="repeatPassword"
                                        name="repeatPassword"
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        label="Repeat Password"
                                        variant="outlined"
                                        maxLength={50}
                                        value={state.repeatPassword}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                    >
                                        Register
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.validationText}
                                    >
                                        {error.firstName ? (
                                            <>
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                                {error.firstName}
                                            </>
                                        ) : (
                                            <>
                                                <CheckCircleIcon
                                                    className={
                                                        classes.checkIcon
                                                    }
                                                />
                                                First Name
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.validationText}
                                    >
                                        {error.lastName ? (
                                            <>
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                                {error.lastName}
                                            </>
                                        ) : (
                                            <>
                                                <CheckCircleIcon
                                                    className={
                                                        classes.checkIcon
                                                    }
                                                />
                                                Last Name
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.validationText}
                                    >
                                        {error.email ? (
                                            <>
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                                {error.email}
                                            </>
                                        ) : (
                                            <>
                                                <CheckCircleIcon
                                                    className={
                                                        classes.checkIcon
                                                    }
                                                />
                                                Email
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.validationText}
                                    >
                                        {error.dodin ? (
                                            <>
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                                {error.dodin}
                                            </>
                                        ) : (
                                            <>
                                                <CheckCircleIcon
                                                    className={
                                                        classes.checkIcon
                                                    }
                                                />
                                                DoD ID Number
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.validationText}
                                    >
                                        {error.phone ? (
                                            <>
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                                {error.phone}
                                            </>
                                        ) : (
                                            <>
                                                <CheckCircleIcon
                                                    className={
                                                        classes.checkIcon
                                                    }
                                                />
                                                Phone Number
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.validationText}
                                    >
                                        {error.affiliation ? (
                                            <>
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                                {error.affiliation}
                                            </>
                                        ) : (
                                            <>
                                                <CheckCircleIcon
                                                    className={
                                                        classes.checkIcon
                                                    }
                                                />
                                                Military Affiliation
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                {disableUnit ? (
                                    ""
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.validationText}
                                        >
                                            {error.unit ? (
                                                <>
                                                    <ErrorIcon
                                                        className={
                                                            classes.errorIcon
                                                        }
                                                    />
                                                    {error.unit}
                                                </>
                                            ) : (
                                                <>
                                                    <CheckCircleIcon
                                                        className={
                                                            classes.checkIcon
                                                        }
                                                    />
                                                    Unit
                                                </>
                                            )}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.validationText}
                                    >
                                        {error.base ? (
                                            <>
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                                {error.base}
                                            </>
                                        ) : (
                                            <>
                                                <CheckCircleIcon
                                                    className={
                                                        classes.checkIcon
                                                    }
                                                />
                                                Primary Installation
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.validationText}
                                    >
                                        {error.password ? (
                                            <>
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                                {error.password}
                                            </>
                                        ) : (
                                            <>
                                                <CheckCircleIcon
                                                    className={
                                                        classes.checkIcon
                                                    }
                                                />
                                                Password
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.validationText}
                                    >
                                        {repeatError ? (
                                            <>
                                                <ErrorIcon
                                                    className={
                                                        classes.errorIcon
                                                    }
                                                />
                                                {repeatError}
                                            </>
                                        ) : (
                                            <>
                                                <CheckCircleIcon
                                                    className={
                                                        classes.checkIcon
                                                    }
                                                />
                                                Repeat Password
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    checkIcon: {
        color: theme.palette.success.dark,
        marginRight: theme.spacing(1),
    },
    errorIcon: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    showButton: {
        float: "right",
    },
    validationText: {
        display: "flex",
        alignItems: "center",
    },
}));

export default Register;
