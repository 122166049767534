import React, { useEffect, useState } from "react";
import useAPI from "../../../hooks/api/useAPI";
import moment from "moment";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Container,
    Grid,
    makeStyles,
    Paper,
    Popover,
    TextField,
    Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Reservations = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [reservations, setReservations] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popover, setPopover] = useState("");
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [stopDate, setStopDate] = useState(moment().add(7,"day").format("YYYY-MM-DD"));

    const getReservations = async () => {
        try {
            let response = await fetch(
                `${api.path}/reservations/my/?start=${startDate}&stop=${stopDate}`
            );
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setReservations(response);
        } catch (err) {
            alert("Error: " + err.message);
        }
    };

    const cancelReservation = async (id) => {
        try {
            let response = await fetch(`${api.path}/reservations/cancel`, {
                method: "PATCH",
                body: JSON.stringify({
                    _id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            alert("Your Reservation has been Cancelled.");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
    };

    const checkInReservation = async (id, start) => {
        try {
            let path;
            if (moment() < moment(start).add(5, "minute")) {
                path = `${api.path}/reservations/checkin`;
            } else {
                path = `${api.path}/reservations/latecheckin`;
            }
            let response = await fetch(path, {
                method: "PATCH",
                body: JSON.stringify({
                    _id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            alert("Checked In!");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
    };

    const checkOutReservation = async (id, stop) => {
        try {
            let path;
            if (moment() < moment(stop).add(5, "minute")) {
                path = `${api.path}/reservations/checkout`;
            } else {
                path = `${api.path}/reservations/latecheckout`;
            }
            let response = await fetch(path, {
                method: "PATCH",
                body: JSON.stringify({
                    _id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            alert("Checked Out!");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
    };

    useEffect(() => {
        getReservations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, stopDate]);

    const popoverOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setPopover(`popover-${id}`);
    };

    const popoverClose = () => {
        setAnchorEl(null);
        setPopover("");
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const resList = reservations.map((res) => {
        const stopDTG = moment(res.stop);
        const startDTG = moment(res.start);
        const startTime = startDTG.format("HH:mm");
        const stopTime = stopDTG.format("HH:mm");
        let status = "";
        if (res.status !== "ok") {
            status = res.status;
        } else if (res.checkedIn && !res.checkedOut) {
            status = "Checked In";
        } else if (res.checkedOut) {
            status = "Complete";
        } else if (res.cancelled) {
            status = "Cancelled";
        } else {
            if (moment(res.start) > moment()) {
                status = "Upcoming";
            } else {
                status = "Unknown";
            }
        }
        return (
            <Accordion
                expanded={expanded === `panel${res._id}`}
                onChange={handleChange(`panel${res._id}`)}
                key={`panel${res._id}`}
                className={classes.accordion}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${res._id}bh-content`}
                    id={`panel${res._id}bh-header`}
                >
                    <Typography
                        variant="body2"
                        className={classes.name}
                    >{`${res.facility.name} - ${res.service.name}`}</Typography>
                    <Typography variant="body2" className={classes.status}>
                        {status}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails id={`panel${res._id}bh-details`}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {moment(res.start).format("MMM DD, YYYY")}
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Start</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {startTime}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Stop</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {stopTime}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Checked In</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {res.checkedIn
                                            ? moment(res.checkedIn).format(
                                                  "HH:mm"
                                              )
                                            : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Checked Out</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {res.checkedOut
                                            ? moment(res.checkedOut).format(
                                                  "HH:mm"
                                              )
                                            : ""}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Visit Duration</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {res.checkedIn && res.checkedOut
                                            ? `${moment(res.checkedOut).diff(
                                                  moment(res.checkedIn),
                                                  "minutes"
                                              )} minutes`
                                            : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {res.checkedIn && res.checkedOut ? (
                                ""
                            ) : (
                                <Button
                                    variant="contained"
                                    className={classes.optionButton}
                                    onClick={(e) => popoverOpen(e, res._id)}
                                >
                                    Options
                                </Button>
                            )}
                            <Popover
                                id={`popover-${res._id}`}
                                open={popover === `popover-${res._id}`}
                                anchorEl={anchorEl}
                                onClose={popoverClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <Container className={classes.optionContainer}>
                                    {!res.checkedIn && !res.checkedOut ? (
                                        <Button
                                            onClick={() =>
                                                cancelReservation(res._id)
                                            }
                                            fullWidth
                                        >
                                            Cancel Reservation
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                    {res.service.selfCheckIn &&
                                    !res.checkedIn ? (
                                        <Button
                                            onClick={() =>
                                                checkInReservation(
                                                    res._id,
                                                    res.start
                                                )
                                            }
                                        >
                                            Check In
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                    {res.service.selfCheckOut &&
                                    !res.checkedOut ? (
                                        <Button
                                            onClick={() =>
                                                checkOutReservation(
                                                    res._id,
                                                    res.stop
                                                )
                                            }
                                        >
                                            Check Out
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </Container>
                            </Popover>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    });

    return (
        <Container maxWidth="sm">
            <Typography component="h1" variant="h2">
                My Reservations
            </Typography>
            <Paper className={classes.formContainer}>
                <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    className={classes.textField}
                    defaultValue={startDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <TextField
                    id="stopDate"
                    label="Stop Date"
                    type="date"
                    variant="outlined"
                    className={classes.textField}
                    defaultValue={stopDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setStopDate(e.target.value)}
                />
            </Paper>
            {reservations.length > 0 ? (
                resList
            ) : (
                <Typography>
                    No reservations in set date range. Please change the date
                    range to find reservations.
                </Typography>
            )}
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    upcomingContainer: {
        height: 432,
        padding: 0,
        overflowY: "auto",
        overflowX: "hidden",
    },
    dateTime: {},
    name: {
        fontWeight: "bold",
        flexBasis: "70%",
        flexShrink: 0,
    },
    optionButton: {
        width: 150,
        float: "right",
    },
    badStatusText: {
        color: theme.palette.error.main,
    },
    goodStatusText: {
        color: theme.palette.success.main,
    },
    badStatusBackground: {
        backgroundColor: theme.palette.error.light,
    },
    goodStatusBackground: {
        // backgroundColor: theme.palette.success.light,
    },
    optionContainer: {
        display: "flex",
        flexDirection: "column",
    },
    formContainer: {
        margin: theme.spacing(0, 0, 3, 0),
        padding: theme.spacing(1),
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-evenly",
    },
    textField: {
        margin: theme.spacing(1),
    },
}));

export default Reservations;
