import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useAPI from "../../../hooks/api/useAPI";
import {
    Button,
    Typography,
    CssBaseline,
    Container,
    TextField,
} from "@material-ui/core";

const ResendCode = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const resendCode = async (e) => {
        e.preventDefault();
        if (error) return;
        try {
            let response = await fetch(`${api.path}/verify/resend`, {
                method: "POST",
                body: JSON.stringify({
                    email: email,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                setMessage(
                    "If your email is in our system, a new verification request has been sent. Please check your email. If it does not appear in your inbox, please check your junk mail."
                );
            } else {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
        } catch (err) {
            setMessage("There was an issue submitting your request.");
        }
    };

    useEffect(() => {
        if (email.length > 6) {
            console.log(email.indexOf("@"));
            if (email.indexOf("@") === -1) {
                setError("Please enter a valid email address");
            } else {
                setError("");
            }
        }
    }, [email]);

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography variant="h2">Resend Email Verification</Typography>
                <Typography className={classes.bodyText}>
                    Please enter your email below. If it is found in our system,
                    we will send you an email to verify your account
                    information.
                </Typography>
                <form onSubmit={resendCode} noValidate>
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        id="email"
                        name="email"
                        type="email"
                        label="Email"
                        variant="outlined"
                        maxLength={50}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={error ? true : false}
                        helperText={error}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={error ? true : false}
                    >
                        Send Verification
                    </Button>
                </form>
                <Link className={classes.link} to="/">
                    Return to Main Page
                </Link>
                <Typography className={classes.bodyText}>{message}</Typography>
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    alignCenter: {
        textAlign: "center",
    },
    bodyText: {
        margin: theme.spacing(2),
    },
    link: {
        margin: theme.spacing(1),
        fontSize: "1rem",
    },
    paper: {
        marginTop: theme.spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

export default ResendCode;
