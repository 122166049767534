import {
    TextField,
    FormControlLabel,
    Switch,
    Typography,
    Grid,
    Container,
    Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useAPI from "../../../../hooks/api/useAPI";

const AddService = (props) => {
    const api = useAPI();
    const classes = useStyles();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [maxGuests, setMaxGuests] = useState(0);
    const [subGuestsFromFacility, setSubGuestsFromFacility] = useState(true);
    const [walkInOnly, setWalkInOnly] = useState(false);
    const [groupsOnly, setGroupsOnly] = useState(false);
    const [blockTimeOnly, setBlockTimeOnly] = useState(false);
    const [reservationDuration, setReservationDuration] = useState(0);
    const [reservationInterval, setReservationInterval] = useState(15);
    const [selfCheckIn, setSelfCheckIn] = useState(true);
    const [selfCheckOut, setSelfCheckOut] = useState(true);

    useEffect(() => {
        if (
            name !== "" ||
            description !== "" ||
            maxGuests !== 0 ||
            subGuestsFromFacility !== true ||
            walkInOnly !== false ||
            groupsOnly !== false ||
            blockTimeOnly !== false ||
            reservationDuration !== 0 ||
            reservationInterval !== 15 ||
            selfCheckIn !== true ||
            selfCheckOut !== true
        ) {
            props.handleChanges(true);
        } else {
            props.handleChanges(false);
        }
    }, [
        name,
        description,
        maxGuests,
        subGuestsFromFacility,
        walkInOnly,
        groupsOnly,
        blockTimeOnly,
        reservationDuration,
        reservationInterval,
        selfCheckIn,
        selfCheckOut,
        props,
    ]);

    const submitForm = async (e) => {
        e.preventDefault();
        if (props.dirty) {
            try {
                const service = {
                    facilityId: props.facilityId,
                    name: name,
                    description: description,
                    maxGuests: maxGuests,
                    selfCheckIn: selfCheckIn,
                    selfCheckOut: selfCheckOut,
                    subGuestsFromFacility: subGuestsFromFacility,
                    walkInOnly: walkInOnly,
                    groupsOnly: groupsOnly,
                    blockTimeOnly: blockTimeOnly,
                    reservationDuration: reservationDuration,
                    reservationInterval: reservationInterval,
                };
                let response = await fetch(`${api.path}/service`, {
                    method: "POST",
                    body: JSON.stringify(service),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    const statusCode = response.status;
                    response = await response.json();
                    throw new Error(`${statusCode} - ${response.message}`);
                }
                response = await response.json();
                if (response.nModified !== 1) {
                    throw new Error(`Entry not updated.`);
                } else {
                    alert("Settings updated!");
                    props.handleUpdate();
                }
            } catch (err) {
                alert(err.message);
                return;
            }
        }
    };

    return (
        <div>
            <Typography variant="h2">Add Service</Typography>
            <form className={classes.form} onSubmit={submitForm} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="name"
                            label="Service Name"
                            variant="outlined"
                            value={name}
                            onChange={(e) =>
                                setName(e.target.value.slice(0, 30))
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            label="Service Description"
                            variant="outlined"
                            value={description}
                            onChange={(e) =>
                                setDescription(e.target.value.slice(0, 140))
                            }
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="maxGuests"
                            label="Maximum Number of Guests"
                            variant="outlined"
                            type="number"
                            value={maxGuests}
                            onChange={(e) => setMaxGuests(e.target.value)}
                            fullWidth
                            helperText="Set to zero (0) for unlimited"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={selfCheckIn}
                            control={<Switch color="primary" />}
                            label="Self Check-In"
                            onChange={() => setSelfCheckIn(!selfCheckIn)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={selfCheckOut}
                            control={<Switch color="primary" />}
                            label="Self Check-Out"
                            onChange={() => setSelfCheckOut(!selfCheckOut)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={subGuestsFromFacility}
                            control={<Switch color="primary" />}
                            label="Subtrack Guests from Facility"
                            onChange={() =>
                                setSubGuestsFromFacility(!subGuestsFromFacility)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={walkInOnly}
                            control={<Switch color="primary" />}
                            label="Walk In Only"
                            onChange={() => setWalkInOnly(!walkInOnly)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={groupsOnly}
                            control={<Switch color="primary" />}
                            label="Groups Only"
                            onChange={() => setGroupsOnly(!groupsOnly)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={blockTimeOnly}
                            control={<Switch color="primary" />}
                            label="Block Time Only"
                            onChange={() => setBlockTimeOnly(!blockTimeOnly)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="reservationDuration"
                            label="Reservation Duration"
                            variant="outlined"
                            type="number"
                            value={reservationDuration}
                            onChange={(e) =>
                                setReservationDuration(e.target.value)
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="reservationInterval"
                            label="Reservation Interval"
                            variant="outlined"
                            type="number"
                            value={reservationInterval}
                            onChange={(e) =>
                                setReservationInterval(e.target.value)
                            }
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Container className={classes.footerButtons}>
                    <Button
                        fullWidth
                        className={classes.footerButton}
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.footerButton}
                        fullWidth
                        disabled={!props.dirty}
                        type="submit"
                    >
                        Save
                    </Button>
                </Container>
            </form>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    footerButtons: {
        position: "absolute",
        left: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    footerButton: {
        margin: theme.spacing(2),
    },
}));

export default AddService;
