import { useState } from "react";
import useAPI from "./../api/useAPI";

const useProvideAuth = () => {
    const api = useAPI();

    const [user, setUser] = useState(sessionStorage.getItem("user") || null);
    const [admin, setAdmin] = useState(sessionStorage.getItem("admin") || null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const login = async (user) => {
        try {
            let response = await fetch(`${api.path}/session`, {
                method: "POST",
                body: JSON.stringify(user),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setUser(response.userName);
            setAdmin(response.worker);
            sessionStorage.setItem("user", response.userName);
            sessionStorage.setItem("admin", response.worker);
            return "complete";
        } catch (err) {
            return err;
        }
    };

    const selfRegister = async (user) => {
        try {
            let response = await fetch(`${api.path}/users/self-register`, {
                method: "POST",
                body: JSON.stringify(user),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            return response;
        } catch (err) {
            return err;
        }
    };
    
    const workerRegister = async (user) => {
        try {
            let response = await fetch(`${api.path}/users/worker-register`, {
                method: "POST",
                body: JSON.stringify(user),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            return response;
        } catch (err) {
            return err;
        }
    };

    const logout = async () => {
        try {
            let response = await fetch(`${api.path}/session`, {
                method: "DELETE",
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            sessionStorage.clear("user");
            sessionStorage.clear("admin");
            setUser("");
            setAdmin(false);
            return "complete";
        } catch (err) {
            return err;
        }
    };

    const verify = async () => {
        try {
            let response = await fetch(`${api.path}/session`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            if (response.userName) {
                setUser(response.userName);
                setAdmin(response.worker);
                sessionStorage.setItem("user", response.userName);
                sessionStorage.setItem("admin", response.worker);
                return "complete";
            }
            return response.message;
        } catch (err) {
            return err;
        }
    };

    const resetPassword = async (request) => {
        try {
            let response = await fetch(`${api.path}/password/reset`, {
                method: "POST",
                body: JSON.stringify(request),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            return response.message;
        } catch (err) {
            return err.message;
        }
    };

    return {
        user,
        admin,
        email,
        password,
        setEmail,
        setPassword,
        login,
        logout,
        verify,
        selfRegister,
        workerRegister,
        resetPassword,
    };
};

export default useProvideAuth;
