import { Container, Typography } from "@material-ui/core";
import React from "react";
import { Line } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";

const OccupancyChart = (props) => {
    let classes = useStyles();
    

    const data = {
        labels: props.chartData.timeSlot,
        datasets: [
            {
                label: "Reservations",
                data: props.chartData.count,
                fill: true,
                backgroundColor: "rgb(152, 189, 211, 0.5)",
                borderColor: "rgba(0, 90, 143, 1)",
            },
        ],
    };
    let options
    if (props.max > 0) {
        options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
            maintainAspectRatio: false,
        };
    } else {
        options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
            },
            maintainAspectRatio: false,
        };
    }

    return (
        <Container className={classes.occupancyContainer}>
            <Typography variant="h4">Expected Occupancy {props.chartData.timeSlot ? "" : "- CLOSED TODAY"}</Typography>
            <div className={classes.lineChart}>
                <Line data={data} options={options} height={400} />
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    occupancyContainer: {
        height: 420,
        padding: 0,
    },
    lineChart: {
        maxHeight: 350,
    },
}));

export default OccupancyChart;
