/* eslint-disable no-useless-escape */
export const validator = async (name, value, api) => {
    let err = "";
    let response = "";
    switch (name) {
        case "firstName":
            if (!value) {
                err = "First Name";
                break;
            }
            if (!/^[A-Za-z\s\-]+$/.test(value)) {
                err = "First Name can only contain letters, spaces, or dashes";
                break;
            }
            break;
        case "lastName":
            if (!value) {
                err = "Last Name";
                break;
            }
            if (!/^[A-Za-z\s\-]+$/.test(value)) {
                err = "Last Name can only contain letters, spaces, or dashes";
                break;
            }
            break;
        case "dodin":
            if (!value) {
                err = "DoD ID Number";
                break;
            }
            if (value.length < 10) {
                err = "DoD ID Number must be 10 digits";
                break;
            }
            response = await fetch(`${api.path}/users/check?dodin=${value}`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                err = `${statusCode} - ${response.message}`;
                break;
            } else {
                response = await response.json();
                if (response.count > 0) {
                    err = "DoD ID Number already used";
                    break;
                }
            }
            break;
        case "email":
            if (!value) {
                err = "Email";
                break;
            }
            if (value.indexOf("@") === -1) {
                err = "Email";
                break;
            }
            response = await fetch(`${api.path}/users/check?email=${value}`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                err = `${statusCode} - ${response.message}`;
                break;
            } else {
                response = await response.json();
                if (response.count > 0) {
                    err = "Email already used";
                    break;
                }
            }
            break;
        case "phone":
            const phoneNum = parseInt(value.replaceAll(/[\+\(\)-\s]/g, ""));
            if (!phoneNum) {
                err = "Phone Number";
                break;
            }
            if (phoneNum.toString().length !== 10) {
                err =
                    "Phone Number must contain 10 digits (includes Area Code)";
                break;
            }
            break;
        case "affiliation":
            if (!value) {
                err = "Military Affiliation";
                break;
            }
            break;
        case "unit":
            if (!value) {
                err = "Unit/Sponsors Unit";
                break;
            }
            break;
        case "base":
            if (!value) {
                err = "Primary Installation";
                break;
            }
            break;
        case "password":
            if (
                !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(
                    value
                )
            ) {
                err =
                    "Password must contain: 6-16 characters, 1 capital letter, 1 lowercase letter, 1 number, 1 special character";
                break;
            }
            break;
        default:
            return;
    }
    return err;
};
