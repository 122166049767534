import React from "react";
import { Container, Typography } from "@material-ui/core";

const About = (props) => {
    return (
        <Container>
            <Typography variant="h2">About Garrison Scheduler</Typography>

            <Typography variant="body1">
                The Garrison Scheduler is an application that was initially
                developed for the Buckley AFB Fitness Center to handle visitor
                flows during restricted times. This application was created by
                Capt Scott Hubert of the Space Delta 4 Combat Development
                Division in support of the Buckley Garrison.
            </Typography>
        </Container>
    );
};

export default About;
