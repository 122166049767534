import React, { useState, useEffect } from "react";
import useAPI from "./../../../../hooks/api/useAPI";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";

const ViewProfile = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState();
    const [error, setError] = useState("");

    const getUserInfo = async () => {
        try {
            let response = await fetch(`${api.path}/users/user/${props.id}`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setUserInfo(response);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        getUserInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {userInfo ? (
                <>
                    <DialogTitle>
                        {userInfo.profile.firstName} {userInfo.profile.lastName}
                        's Profile
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                First Name:
                            </Grid>
                            <Grid item xs={6}>
                                {userInfo.profile.firstName}
                            </Grid>
                            <Grid item xs={6}>
                                Last Name:
                            </Grid>
                            <Grid item xs={6}>
                                {userInfo.profile.lastName}
                            </Grid>
                            <Grid item xs={6}>
                                Email:
                            </Grid>
                            <Grid item xs={6}>
                                {userInfo.email}
                            </Grid>
                            <Grid item xs={6}>
                                DoD ID Number:
                            </Grid>
                            <Grid item xs={6}>
                                {userInfo.dodin}
                            </Grid>
                            <Grid item xs={6}>
                                Military Affiliation:
                            </Grid>
                            <Grid item xs={6}>
                                {userInfo.profile.affiliation}
                            </Grid>
                            <Grid item xs={6}>
                                Unit:
                            </Grid>
                            <Grid item xs={6}>
                                {userInfo.profile.unit
                                    ? userInfo.profile.unit
                                    : "N/A"}
                            </Grid>
                            <Grid item xs={6}>
                                Base:
                            </Grid>
                            <Grid item xs={6}>
                                {userInfo.profile.base.name}
                            </Grid>
                            <Grid item xs={6}>
                                Phone Number:
                            </Grid>
                            <Grid item xs={6}>
                                {userInfo.profile.phone}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>
            ) : (
                <>
                <DialogTitle>View User Profile</DialogTitle>
                <DialogContent><DialogContentText>Please Wait...</DialogContentText></DialogContent>
                </>
            )}
            {error ? <Typography>{error}</Typography> : ""}
            <DialogActions>
                <Button onClick={props.handleClose} color="primary" variant="contained">Close</Button>
            </DialogActions>
        </>
    );
};

const useStyles = makeStyles((theme) => ({}));

export default ViewProfile;
