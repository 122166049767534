import React from "react";
import {
    Container,
    Typography,
    CssBaseline,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Selector = (props) => {
    const classes = useStyles();

    const accordions = props.facility.map((item, index) => (
        <Accordion className={classes.accordion} key={item._id}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aira-controls={`panel${index}-header`}
                id={`panel${index}-header`}
                
            >
                <Typography className={classes.accordHeading}>
                    {item.name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={1}>
                    {item.service.map((service) => {
                        return (
                            <Grid item lg={12}  key={service._id}>
                                <Button
                                    id={service._id}
                                    key={service._id}
                                    variant={
                                        props.selected === service._id
                                            ? "contained"
                                            : "text"
                                    }
                                    color={
                                        props.selected === service._id
                                            ? "secondary"
                                            : "default"
                                    }
                                    onClick={() =>
                                        props.handleSelect(item._id, service._id)
                                    }
                                >
                                    {service.name}
                                </Button>
                            </Grid>
                        );
                    })}

                    {props.administrator.includes(item._id) ? (
                        <Grid item lg={12}>
                            <Button
                                id={item._id}
                                variant={
                                    props.selected === item._id
                                        ? "contained"
                                        : "text"
                                }
                                color={
                                    props.selected === item._id
                                        ? "secondary"
                                        : "default"
                                }
                                onClick={() => props.handleSelect(item._id)}
                            >
                                Administration
                            </Button>
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    ));

    return (
        <Container>
            <CssBaseline />
            <div className={classes.paper}>{accordions}</div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    accordion: {
        width: "100%",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    serviceContainer: {
        padding: 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
}));

export default Selector;
