import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useAPI from "../../../hooks/api/useAPI";
import {
    Button,
    Typography,
    CssBaseline,
    Container,
    TextField,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { validator } from "./../../../functions/validation";
import useAuth from "./../../../hooks/auth/useAuth";

const ResetPassword = () => {
    const api = useAPI();
    const auth = useAuth();
    const classes = useStyles();
    const [resetCode, setResetCode] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [state, setState] = useState("");
    const [error, setError] = useState(
        "Password must contain: 6-16 characters, 1 capital letter, 1 lowercase letter, 1 number, 1 special character"
    );
    const [repeatError, setRepeatError] = useState("Passwords must match");
    const [message, setMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const validate = async () => {
        let err = await validator("password", password, api);
        setError(err);
    };

    const resetPassword = async (e) => {
        e.preventDefault();
        await validate();
        if (error && repeatError) {
            alert("Please ensure all fields are filled in properly.");
            return;
        }
        const request = {
            code: resetCode,
            newPassword: password,
        };
        const response = await auth.resetPassword(request);
        if (response === "Password updated") {
            setState("complete");
        } else {
            setState("error");
            setMessage(response);
        }
    };

    const checkCode = async (code) => {
        if (code) {
            try {
                let response = await fetch(`${api.path}/password/checkcode`, {
                    method: "POST",
                    body: JSON.stringify({
                        code: code,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    const statusCode = response.status;
                    response = await response.json();
                    throw new Error(`${statusCode} - ${response.message}`);
                }
                response = await response.json();
                if (response.message === "Code found") {
                    setState("code found");
                } else {
                    setState("code not found");
                }
            } catch (err) {
                setState("error");
                setMessage(err.message);
            }
        } else {
            setState("no code");
        }
    };

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get("code");
        setResetCode(code);
        checkCode(code);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (repeatPassword === password && repeatPassword.length > 2) {
            setRepeatError("");
        } else {
            setRepeatError("Passwords must mtach");
        }
    }, [password, repeatPassword]);

    let display;
    switch (state) {
        case "code found":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        Please set your new password below.
                    </Typography>
                    <form onSubmit={resetPassword} noValidate>
                        <TextField
                            required={true}
                            fullWidth
                            margin="normal"
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            variant="outlined"
                            maxLength={50}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={() => validate()}
                        />
                        <Button
                            className={classes.showButton}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? "Hide Password" : "Show Password"}
                        </Button>
                        <TextField
                            required={true}
                            fullWidth
                            margin="normal"
                            id="repeatPassword"
                            name="repeatPassword"
                            type={showPassword ? "text" : "password"}
                            label="Repeat Password"
                            variant="outlined"
                            maxLength={50}
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                        />
                        <Typography className={classes.validationText}>
                            {error ? (
                                <>
                                    <ErrorIcon className={classes.errorIcon} />
                                    {error}
                                </>
                            ) : (
                                <>
                                    <CheckCircleIcon
                                        className={classes.checkIcon}
                                    />
                                    Password
                                </>
                            )}
                        </Typography>
                        <Typography className={classes.validationText}>
                            {repeatError ? (
                                <>
                                    <ErrorIcon className={classes.errorIcon} />
                                    {repeatError}
                                </>
                            ) : (
                                <>
                                    <CheckCircleIcon
                                        className={classes.checkIcon}
                                    />
                                    Repeat Password
                                </>
                            )}
                        </Typography>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={error || repeatError ? true : false}
                        >
                            Reset Password
                        </Button>
                    </form>
                    <Typography className={classes.bodyText}>
                        {message}
                    </Typography>
                </>
            );
            break;
        case "code not found":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        Your reset code was not found. Please click the link
                        below to resend your reset link.
                    </Typography>
                    <div className={classes.alignCenter}>
                        <Link className={classes.link} to="/login">
                            Login
                        </Link>
                        <Link className={classes.link} to="/send-reset">
                            Send Reset Link
                        </Link>
                    </div>
                </>
            );
            break;
        case "error":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        There was an error resetting your password. Please try
                        again or click the link below to resend your reset link.
                        If this is your second time on this screen, please send
                        an email to{" "}
                        <a href="mailto:support@weinhauscreations.com">
                            support@weinhauscreations.com
                        </a>{" "}
                        with the text below.
                    </Typography>
                    <Typography className={classes.bodyText}>
                        {message}
                    </Typography>
                    <div className={classes.alignCenter}>
                        <Link className={classes.link} to="/send-reset">
                            Send Reset Link
                        </Link>
                    </div>
                </>
            );
            break;
        case "no code":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        No code was submitted. Please click on the link in your
                        email to reset your password.
                    </Typography>
                </>
            );
            break;
        case "complete":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        Your password has been successfully reset. Click the
                        link below to login with your updated password.
                    </Typography>
                    <div className={classes.alignCenter}>
                        <Link className={classes.link} to="/login">
                            Login
                        </Link>
                    </div>
                </>
            );
            break;
        default:
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        Please wait a moment while we verify your code.
                    </Typography>
                </>
            );
            break;
    }

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography variant="h2">Reset Password</Typography>
                {display}
                <Link className={classes.link} to="/">
                    Return to Main Page
                </Link>
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    alignCenter: {
        textAlign: "center",
    },
    bodyText: {
        margin: theme.spacing(2),
    },
    checkIcon: {
        color: theme.palette.success.dark,
        marginRight: theme.spacing(1),
    },
    errorIcon: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1),
    },
    link: {
        margin: theme.spacing(1),
        fontSize: "1rem",
    },
    paper: {
        marginTop: theme.spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    showButton: {
        float: "right",
    },
    validationText: {
        display: "flex",
        alignItems: "center",
    },
}));

export default ResetPassword;
