import React, { useState } from "react";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import FindSlots from "../../../common/FindSlots";

const CreateReservation = (props) => {

    return (
        <>
            <DialogTitle id="dialog-createReservation">
                Create Reservation
            </DialogTitle>
            <DialogContent>
            <FindSlots userId={props.id} facilityId={props.facilityId} serviceId={props.serviceId} handleSubmit={props.handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </>
    );
};

export default CreateReservation;
