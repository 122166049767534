import React, { useState, useEffect } from "react";
import useAPI from "../../../hooks/api/useAPI";
import { Container, CssBaseline, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useAuth from "../../../hooks/auth/useAuth";

const Profile = () => {
    const api = useAPI();
    const auth = useAuth();
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState();
    const [error, setError] = useState("");

    const getUserInfo = async () => {
        try {
            let response = await fetch(`${api.path}/users/user`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setUserInfo(response);
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h2">
                    Profile
                </Typography>
                {userInfo ? (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            First Name:
                        </Grid>
                        <Grid item xs={6}>
                            {userInfo.profile.firstName}
                        </Grid>
                        <Grid item xs={6}>
                            Last Name:
                        </Grid>
                        <Grid item xs={6}>
                            {userInfo.profile.lastName}
                        </Grid>
                        <Grid item xs={6}>
                            Email:
                        </Grid>
                        <Grid item xs={6}>
                            {userInfo.email}
                        </Grid>
                        <Grid item xs={6}>
                            DoD ID Number:
                        </Grid>
                        <Grid item xs={6}>
                            {userInfo.dodin}
                        </Grid>
                        <Grid item xs={6}>
                            Military Affiliation:
                        </Grid>
                        <Grid item xs={6}>
                            {userInfo.profile.affiliation}
                        </Grid>
                        <Grid item xs={6}>
                            Unit:
                        </Grid>
                        <Grid item xs={6}>
                            {userInfo.profile.unit
                                ? userInfo.profile.unit
                                : "N/A"}
                        </Grid>
                        <Grid item xs={6}>
                            Base:
                        </Grid>
                        <Grid item xs={6}>
                            {userInfo.profile.base.name}
                        </Grid>
                        <Grid item xs={6}>
                            Phone Number:
                        </Grid>
                        <Grid item xs={6}>
                            {userInfo.profile.phone}
                        </Grid>
                    </Grid>
                ) : (
                    <Typography>Please wait...</Typography>
                )}
                {error ? <Typography>{error}</Typography> : ""}
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

export default Profile;
