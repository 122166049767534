import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useAPI from "./../../../hooks/api/useAPI";
import { Button, Typography, CssBaseline, Container } from "@material-ui/core";

const Verify = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [code, setCode] = useState("");
    const [email, setEmail] = useState("");
    const [state, setState] = useState("");
    const [message, setMessage] = useState("");

    const checkCode = async (code) => {
        setCode(code);
        if (code) {
            try {
                let response = await fetch(`${api.path}/verify`, {
                    method: "POST",
                    body: JSON.stringify({
                        code: code,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    const statusCode = response.status;
                    response = await response.json();
                    throw new Error(`${statusCode} - ${response.message}`);
                }
                response = await response.json();
                if (response.message === "User Verified") {
                    setEmail(response.email);
                    setState("verified");
                } else if (response.message === "Set Password") {
                    setEmail(response.email);
                    setState("password");
                } else {
                    setState("not verified");
                }
            } catch (err) {
                setState("error");
                setMessage(err.message);
            }
        } else {
            setState("no code");
        }
    };
    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let code = params.get("code");
        checkCode(code);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let display;
    switch (state) {
        case "verified":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        Your email ({email}) has been verified. Please continue
                        to the login page to use Garrison Scheduler.
                    </Typography>
                    <div className={classes.alignCenter}>
                        <Link className={classes.link} to="/login">
                            Login
                        </Link>
                    </div>
                </>
            );
            break;
        case "not verified":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        Your verification code was not found. This is typically
                        because your account has already been verified.
                    </Typography>
                    <Typography className={classes.bodyText}>
                        Please try logging into your account. If you believe
                        this is in error, please click the button below to
                        resend the verification email and generate a new code.
                    </Typography>
                    <div className={classes.alignCenter}>
                        <Link className={classes.link} to="/login">
                            Login
                        </Link>
                        <Link className={classes.link} to="/send-verify">
                            Resend Verification
                        </Link>
                    </div>
                </>
            );
            break;
        case "error":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        There was an error verifying your email. Please try
                        again or click the link below to resend your
                        verification code. If this is your second time on this
                        screen, please send an email to{" "}
                        <a href="mailto:support@weinhauscreations.com">
                            support@weinhauscreations.com
                        </a>{" "}
                        with the text below.
                    </Typography>
                    <Typography className={classes.bodyText}>
                        {message}
                    </Typography>
                    <div className={classes.alignCenter}>
                        <Link className={classes.link} to="/send-verify">
                            Resend Verification
                        </Link>
                    </div>
                </>
            );
            break;
        case "no code":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        No code was submitted. Please click on the link in your
                        email to verify your email.
                    </Typography>
                </>
            );
            break;
        case "password":
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        Your email ({email}) has been verified. Please click the
                        link below to set your password.
                    </Typography>
                    <div className={classes.alignCenter}>
                        <Link
                            className={classes.link}
                            to={`/reset-password?code=${code}`}
                        >
                            Login
                        </Link>
                    </div>
                </>
            );
            break;
        default:
            display = (
                <>
                    <Typography className={classes.bodyText}>
                        Please wait a moment while we verify your code.
                    </Typography>
                </>
            );
            break;
    }

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography variant="h2">Thank you for registering!</Typography>
                {display}
                <Link className={classes.link} to="/">
                    Return to Main Page
                </Link>
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    alignCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    bodyText: {
        margin: theme.spacing(2),
    },
    link: {
        margin: theme.spacing(1),
        fontSize: "1rem",
    },
    paper: {
        marginTop: theme.spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

export default Verify;
