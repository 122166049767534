import { useState } from "react";

const useProvideAPI = () => {
    const defaultPath = "/api/v2";
    const [path, setPath] = useState(defaultPath);

    return {
        path,
        setPath,
    };
};

export default useProvideAPI;
