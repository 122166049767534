import React, { useState, useEffect } from "react";
import useAPI from "./../../../../hooks/api/useAPI";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    Grid,
    Button,
    Container,
    FormControlLabel,
    Switch,
    TextField,
} from "@material-ui/core";

const ServiceHours = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    const [hours, setHours] = useState(props.hours);

    useEffect(() => {
        if (hours !== props.hours) {
            props.handleChanges(true);
        } else {
            props.handleChanges(false);
        }
    }, [hours, props]);

    const updateHours = (field, value, index) => {
        let hoursArray = [...hours];
        let hourObject = hours[index];
        hourObject[field] = value;
        if (field === "closed" && value === true) {
            hourObject["openTime"] = "";
            hourObject["closeTime"] = "";
        }
        hoursArray[index] = hourObject;
        setHours(hoursArray);
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (props.dirty) {
            try {
                const body = {
                    facilityId: props.facilityId,
                    _id: props.serviceId,
                    hours: hours,
                };
                console.log(body);
                let response = await fetch(`${api.path}/service/hours`, {
                    method: "PATCH",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    const statusCode = response.status;
                    response = await response.json();
                    throw new Error(`${statusCode} - ${response.message}`);
                }
                response = await response.json();
                if (response.nModified !== 1) {
                    throw new Error(`Entry not updated.`);
                } else {
                    alert("Hours Updated!");
                    props.handleUpdate();
                }
            } catch (err) {
                alert(err.message);
                return;
            }
        }
    };

    const display = hours.map((day, index) => (
        <div>
            <Typography variant="h4">{days[day.dayOfWeek]}</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormControlLabel
                        checked={day.closed}
                        control={<Switch color="primary" />}
                        label="Closed"
                        onChange={() =>
                            updateHours("closed", !day.closed, index)
                        }
                    />
                </Grid>
                {day.closed ? (
                    ""
                ) : (
                    <>
                        <Grid item xs={6}>
                            <TextField
                                id={`openTime-${index}`}
                                label="Open Time"
                                variant="outlined"
                                type="time"
                                fullWidth
                                defaultValue={day.openTime ? day.openTime : ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                                onBlur={(e) =>
                                    updateHours(
                                        "openTime",
                                        e.target.value,
                                        index
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id={`closeTime-${index}`}
                                label="Close Time"
                                variant="outlined"
                                type="time"
                                fullWidth
                                defaultValue={
                                    day.closeTime ? day.closeTime : ""
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                                onBlur={(e) =>
                                    updateHours(
                                        "closeTime",
                                        e.target.value,
                                        index
                                    )
                                }
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    ));

    return (
        <div>
            <Typography variant="h2">Edit Service Hours </Typography>
            <Typography variant="h3">{props.serviceName}</Typography>
            <form className={classes.form} onSubmit={submitForm} noValidate>
                {display}
                <Container className={classes.footerButtons}>
                    <Button
                        fullWidth
                        className={classes.footerButton}
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.footerButton}
                        fullWidth
                        disabled={!props.dirty}
                        type="submit"
                    >
                        Save
                    </Button>
                </Container>
            </form>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    footerButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    footerButton: {
        margin: theme.spacing(2),
    },
    form: {
        marginTop: theme.spacing(4),
    },
}));

export default ServiceHours;
