import {
    makeStyles,
    Container,
    Drawer,
    CssBaseline,
    Grid,
    Paper,
    Typography,
    Button,
    Dialog,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useAPI from "./../../../hooks/api/useAPI";
import UpcomingReservations from "./UpcomingReservations";
import CheckedInGuests from "./CheckedInGuests";
import { startPolling } from "./../../../functions/polling";
import OccupancyChart from "./OccupancyChart";
import AddNewUser from "./flyouts/AddNewUser";
import moment from "moment";
import LookupUser from "./flyouts/UserLookup";
import CreateReservation from "./popups/CreateReservation";
import ViewProfile from "./popups/ViewProfile";
import ViewReservations from "./popups/ViewReservations";

const ServiceDisplay = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [service, setService] = useState({});
    const [reservations, setReservations] = useState([]);
    const [checkedIn, setCheckedIn] = useState([]);
    const [facilityCount, setFacilityCount] = useState(0);
    const [serviceCount, setServiceCount] = useState(0);
    const [chartData, setChartData] = useState({});
    const [flyoutStatus, setFlyoutStatus] = useState(false);
    const [flyoutForm, setFlyoutForm] = useState("");
    const [flyoutFormIndex, setFlyoutFormIndex] = useState("");
    const [flyoutDirty, setFlyoutDirty] = useState(false);
    const [popupStatus, setPopupStatus] = useState(false);
    const [popupForm, setPopupForm] = useState("");
    const [popupFormId, setPopupFormId] = useState("");
    const [popupFormDetail, setPopupFormDetail] = useState("");
    const [popupDirty, setPopupDirty] = useState("");
    const [refresh, setRefresh] = useState(0);

    const getService = async () => {
        try {
            let response = await fetch(`${api.path}/service/${props.id}`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setService(response);
        } catch (err) {
            alert("Error: " + err.message);
        }
    };

    const getReservations = async () => {
        try {
            let response = await fetch(
                `${api.path}/reservations/today/upcoming/${props.id}`
            );
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setReservations(response);
        } catch (err) {
            alert("Error: " + err.message);
        }
        try {
            let response = await fetch(
                `${api.path}/reservations/checkedin/list/${props.id}`
            );
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setCheckedIn(response);
        } catch (err) {
            alert("Error: " + err.message);
        }
        try {
            let response = await fetch(
                `${api.path}/reservations/checkedin/count/${props.facilityId}/${props.id}`
            );
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setFacilityCount(response.facilityCount);
            setServiceCount(response.serviceCount);
        } catch (err) {
            alert("Error: " + err.message);
        }
        try {
            let response = await fetch(
                `${api.path}/reservations/today/chart/${props.id}`
            );
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setChartData(response);
        } catch (err) {
            alert("Error: " + err.message);
        }
    };

    useEffect(() => {
        getService();
        getReservations();
        return startPolling(getReservations, 30000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, props]);

    const handleOpenFlyout = (type, index = 0) => {
        setFlyoutStatus(true);
        setFlyoutForm(type);
        setFlyoutFormIndex(index);
    };

    const handleFlyoutClose = () => {
        if (flyoutDirty) {
            let response = window.confirm(
                "You have unsaved changes. Would you like to cancel your changes and close this window?"
            );
            if (response) {
                setFlyoutStatus(false);
                setFlyoutForm("");
                setFlyoutDirty(false);
            }
        } else {
            setFlyoutStatus(false);
            setFlyoutForm("");
        }
    };

    const handleFlyoutFormChanges = (value) => {
        setFlyoutDirty(value);
    };

    const handleOpenPopup = (type, id = 0, detail = "") => {
        setPopupStatus(true);
        setPopupForm(type);
        setPopupFormId(id);
        setPopupFormDetail(detail);
    };

    const handleClosePopup = () => {
        if (popupDirty) {
            let response = window.confirm(
                "You have unsaved changes. Would you like to cancel your changes and close this window?"
            );
            if (response) {
                setPopupStatus(false);
                setPopupForm("");
                setPopupDirty(false);
                setFlyoutStatus(false);
                setFlyoutForm("");
                setFlyoutDirty(false);
            }
        } else {
            setPopupStatus(false);
            setPopupForm("");
            setPopupDirty(false);
        }
    };

    const handlePopupFormChange = (value) => {
        setPopupDirty(value);
    };

    const handleUpdated = () => {
        setFlyoutDirty(false);
        setFlyoutStatus(false);
        setFlyoutForm("");
        setPopupStatus(false);
        setPopupForm("");
        setPopupDirty(false);
        getReservations();
    };

    const handleCheckInReservation = async (id) => {
        try {
            const reservation = {
                _id: id,
            };
            let response = await fetch(`${api.path}/reservations/checkin`, {
                method: "PATCH",
                body: JSON.stringify(reservation),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            alert("Patron Checked In!");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
    };

    const handleCheckInNoReservation = async (id) => {
        setFlyoutDirty(false);
        setFlyoutStatus(false);
        setFlyoutForm("");
        try {
            const reservation = {
                user: id,
                facility: props.facilityId,
                service: props.id,
                status: "ok",
                subFromFacility: service.subGuestsFromFacility,
                start: moment(),
                stop: moment().add(service.reservationDuration, "minute"),
                checkedIn: moment(),
            };
            let response = await fetch(`${api.path}/reservations/`, {
                method: "POST",
                body: JSON.stringify(reservation),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            alert("Patron Checked In!");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
    };

    const handleLateCheckIn = async (id) => {
        try {
            const reservation = {
                _id: id,
            };
            let response = await fetch(`${api.path}/reservations/latecheckin`, {
                method: "PATCH",
                body: JSON.stringify(reservation),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            alert("Patron Checked In!");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
    };

    const handleCheckOut = async (id) => {
        try {
            let response = await fetch(`${api.path}/reservations/checkout`, {
                method: "PATCH",
                body: JSON.stringify({
                    _id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            alert("Patron Checked Out!");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
    };

    const handleLateCheckOut = async (id) => {
        try {
            let response = await fetch(
                `${api.path}/reservations/latecheckout`,
                {
                    method: "PATCH",
                    body: JSON.stringify({
                        _id: id,
                    }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            alert("Patron Checked Out!");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
    };

    const handleNoShow = async (id) => {
        try {
            let response = await fetch(`${api.path}/reservations/noshow`, {
                method: "PATCH",
                body: JSON.stringify({
                    _id: id,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            alert("Reservation Cancelled and marked No Show!");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
    };

    const handleCreateReservation = async (user, start, stop) => {
        try {
            const reservation = {
                user: user,
                facility: props.facilityId,
                service: props.id,
                status: "ok",
                subFromFacility: service.subGuestsFromFacility,
                start: moment(start),
                stop: moment(stop),
            };
            let response = await fetch(`${api.path}/reservations/`, {
                method: "POST",
                body: JSON.stringify(reservation),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            alert("Reservation created");
            getReservations();
        } catch (err) {
            alert(err.message);
        }
        handleUpdated();
    };

    const display = service.name ? (
        <div className={classes.wrapper}>
            <Typography variant="h3">{service.name}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper className={classes.paperWrapper}>
                                <Container className={classes.countWrapper}>
                                    <Typography variant="h4">
                                        Patron Counts
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Service
                                    </Typography>
                                    <Typography variant="h3">
                                        {serviceCount}
                                        {service.maxGuests > 0
                                            ? ` / ${service.maxGuests}`
                                            : ""}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Facility
                                    </Typography>
                                    <Typography variant="h3">
                                        {facilityCount}
                                        {props.facility.settings > 0
                                            ? ` / ${props.facility.settings.maxGuests}`
                                            : ""}
                                    </Typography>
                                </Container>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paperWrapper}>
                                <Typography variant="h4">Options</Typography>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>
                                        handleOpenFlyout("addNewUser")
                                    }
                                    color="primary"
                                    fullWidth
                                >
                                    Add New Patron
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>
                                        handleOpenFlyout("lookupUser")
                                    }
                                    color="primary"
                                    fullWidth
                                >
                                    Lookup Patron
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>
                                        handleOpenPopup("viewReservations")
                                    }
                                    color="primary"
                                    fullWidth
                                >
                                    View Reservations
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                {service.walkInOnly ? (
                    ""
                ) : (
                    <Grid item xs={12} md={4}>
                        <Paper className={classes.paperWrapper}>
                            <UpcomingReservations
                                reservations={reservations}
                                handleCheckIn={handleCheckInReservation}
                                handleLateCheckIn={handleLateCheckIn}
                                handleNoShow={handleNoShow}
                                handleOpenPopup={handleOpenPopup}
                            />
                        </Paper>
                    </Grid>
                )}
                <Grid item xs={12} md={service.walkInOnly ? 8 : 4}>
                    <Paper className={classes.paperWrapper}>
                        <CheckedInGuests
                            reservations={checkedIn}
                            handleCheckOut={handleCheckOut}
                            handleLateCheckOut={handleLateCheckOut}
                            handleOpenPopup={handleOpenPopup}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paperWrapper}>
                        <OccupancyChart
                            chartData={chartData}
                            max={service.maxGuests}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    ) : (
        <Typography variant="h4">Please Wait</Typography>
    );

    const flyoutDisplay = () => {
        switch (flyoutForm) {
            case "addNewUser":
                return (
                    <AddNewUser
                        handleChanges={handleFlyoutFormChanges}
                        handleClose={handleFlyoutClose}
                        dirty={flyoutDirty}
                        handleUpdate={handleUpdated}
                        handleCheckIn={handleCheckInNoReservation}
                    />
                );
            case "lookupUser":
                return (
                    <LookupUser
                        handleClose={handleFlyoutClose}
                        handleCheckIn={handleCheckInNoReservation}
                        handleOpenPopup={handleOpenPopup}
                    />
                );
            default:
                return;
        }
    };

    const popupDisplay = () => {
        switch (popupForm) {
            case "createReservation":
                return (
                    <CreateReservation
                        handleChange={handlePopupFormChange}
                        handleClose={handleClosePopup}
                        dirty={popupDirty}
                        handleSubmit={handleCreateReservation}
                        id={popupFormId}
                        detail={popupFormDetail}
                        serviceId={props.id}
                        facilityId={props.facilityId}
                    />
                );
            case "viewProfile":
                return (
                    <ViewProfile
                        handleClose={handleClosePopup}
                        id={popupFormId}
                    />
                );
            case "viewReservations":
                return (
                    <ViewReservations
                        serviceId={props.id}
                        handleClose={handleClosePopup}
                        handleOpenPopup={handleOpenPopup}
                    />
                );
            default:
                return;
        }
    };

    return (
        <Container>
            <CssBaseline />
            <div className={classes.paper}>{display}</div>
            <Drawer
                anchor="right"
                open={flyoutStatus}
                onClose={handleFlyoutClose}
            >
                <Container className={classes.flyoutContainer}>
                    {flyoutDisplay()}
                </Container>
            </Drawer>
            <Dialog
                open={popupStatus}
                onClose={handleClosePopup}
                aria-labelledby={`dialog-${popupForm}`}
                classes={{
                    paper: classes.dialogPaper,
                }}
            >
                {popupDisplay()}
            </Dialog>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    flyoutContainer: {
        height: "100%",
        padding: 0
    },
    wrapper: {
        width: "100%",
    },
    button: {
        marginBottom: theme.spacing(1),
    },
    paperWrapper: {
        padding: theme.spacing(1),
    },
    countWrapper: {
        textAlign: "center",
    },
    dialogPaper: {
        backgroundColor: theme.palette.quaternary.light,
    },
}));

export default ServiceDisplay;
