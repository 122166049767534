import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Container, CssBaseline, Typography } from "@material-ui/core";

const Registered = () => {
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography variant="h2">Thank you for registering!</Typography>
                <Typography className={classes.bodyText}>
                    An email has been sent to the email provided. Please check
                    your email and use the link to verify your email. Once
                    complete, you'll be ready to use the Garrision Scheduler
                    system.
                </Typography>
                <Link to="/">Return to Main Page</Link>
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    bodyText: {
        margin: theme.spacing(2),
    },
}));

export default Registered;
