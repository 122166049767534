import {
    Button,
    Container,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import React, { useState, useEffect } from "react";
import useAPI from "./../../../../hooks/api/useAPI";
import { makeStyles } from "@material-ui/core/styles";

const Administrators = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [ids, setIds] = useState(props.userIds);
    const [userDetails, setUserDetails] = useState([]);
    const [search, setSearch] = useState("");
    const [searchReturns, setSearchReturns] = useState([]);
    const [removed, setRemoved] = useState([]);

    useEffect(() => {
        let error = false;
        let statusCode;
        try {
            fetch(`${api.path}/users/search/ids`, {
                method: "POST",
                body: JSON.stringify({
                    users: ids,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (!res.ok) {
                        error = true;
                        statusCode = res.status;
                    }
                    return res.json();
                })
                .then((res) => {
                    if (error) {
                        throw new Error(`${statusCode} - ${res.message}`);
                    }
                    setUserDetails(res);
                });
        } catch (err) {
            alert(err.message);
        }
    }, [ids, api]);

    useEffect(() => {
        if (ids !== props.userIds) {
            props.handleChanges(true);
        } else {
            props.handleChanges(false);
        }
    }, [ids, props]);

    useEffect(() => {
        let error = false;
        let statusCode;
        try {
            fetch(`${api.path}/users/search?value=${search}`)
                .then((res) => {
                    if (!res.ok) {
                        error = true;
                        statusCode = res.status;
                    }
                    return res.json();
                })
                .then((res) => {
                    if (error) {
                        throw new Error(`${statusCode} - ${res.message}`);
                    }
                    setSearchReturns(res);
                });
        } catch (err) {
            alert(err.message);
        }
    }, [api, search]);

    const addUser = (id) => {
        if (!ids.includes(id)) {
            setIds(ids.concat([id]));
            if (removed.includes(id)) {
                let newRemovedArray = [...removed];
                let index = newRemovedArray.indexOf(id);
                newRemovedArray.splice(index, 1);
                setRemoved(newRemovedArray);
            }
        }
    };

    const removeUser = (id) => {
        if (ids.includes(id)) {
            let newIdArray = [...ids];
            let index = newIdArray.indexOf(id);
            newIdArray.splice(index, 1);
            setIds(newIdArray);
            if (props.userIds.includes(id)) {
                setRemoved(removed.concat([id]));
            }
        }
    };

    const submitChanges = async () => {
        let response = window.confirm("Are you sure about your changes?");
        if (response) {
            try {
                response = await fetch(`${api.path}/facility/administrators`, {
                    method: "POST",
                    body: JSON.stringify({
                        _id: props.facilityId,
                        administrators: ids,
                    }),
                    headers: { "Content-Type": "application/json" },
                });
                if (!response.ok) {
                    const statusCode = response.status;
                    response = await response.json();
                    throw new Error(`${statusCode} - ${response.message}`);
                }
                if (removed.length > 0) {
                    response = await fetch(
                        `${api.path}/facility/administrators`,
                        {
                            method: "DELETE",
                            body: JSON.stringify({
                                _id: props.facilityId,
                                administrators: removed,
                            }),
                            headers: { "Content-Type": "application/json" },
                        }
                    );
                    if (!response.ok) {
                        const statusCode = response.status;
                        response = await response.json();
                        throw new Error(`${statusCode} - ${response.message}`);
                    }
                }
                props.handleUpdate();
            } catch (err) {
                alert(err.message);
            }
        }
    };

    const staffList = userDetails.map((user) => (
        <Container className={classes.userItem}>
            <Container>
                <Typography variant="body1" className={classes.title}>
                    {user.profile.firstName} {user.profile.lastName}
                </Typography>
                <Typography variant="body2" className={classes.subtitle}>
                    {user.dodin}
                </Typography>
            </Container>
            <IconButton
                aria-label="delete"
                onClick={() => removeUser(user._id)}
                className={classes.iconButton}
            >
                <DeleteIcon color="error" />
            </IconButton>
        </Container>
    ));

    const resultList = searchReturns.map((user) => (
        <Container className={classes.userItem}>
            <Container>
                <Typography variant="body1" className={classes.title}>
                    {user.profile.firstName} {user.profile.lastName}
                </Typography>
                <Typography variant="body2" className={classes.subtitle}>
                    {user.dodin}
                </Typography>
            </Container>
            {ids.includes(user._id) ? (
                <IconButton aria-label="done" className={classes.iconButton}>
                    <CheckIcon className={classes.checkIcon} />
                </IconButton>
            ) : (
                <IconButton
                    aria-label="add"
                    onClick={() => addUser(user._id)}
                    className={classes.iconButton}
                >
                    <AddIcon color="primary" />
                </IconButton>
            )}
        </Container>
    ));

    return (
        <div>
            <Typography variant="h2">Edit Administrators</Typography>
            <Container className={classes.container}>
                <Typography variant="h4">Staff List</Typography>
                <div>{staffList}</div>
            </Container>
            <Container className={classes.container}>
                <Typography variant="h4">Add New</Typography>
                <div>
                    <TextField
                        id="search"
                        label="Search Name or DoDIN"
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        fullWidth
                    />
                    <div>{resultList}</div>
                </div>
            </Container>
            <Container className={classes.footerButtons}>
                <Button
                    fullWidth
                    className={classes.footerButton}
                    onClick={props.handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.footerButton}
                    fullWidth
                    disabled={!props.dirty}
                    onClick={submitChanges}
                >
                    Save
                </Button>
            </Container>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(4),
    },
    checkIcon: {
        color: theme.palette.success.main,
    },
    footerButtons: {
        position: "absolute",
        left: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
    },
    footerButton: {
        margin: theme.spacing(2),
    },
    userItem: {
        width: "100%",
        backgroundColor: theme.palette.quaternary.light,
        padding: theme.spacing(1),
        display: "flex",
    },
    iconButton: {
        float: "right",
    },
    title: {
        display: "block",
    },
    subtitle: {
        marginLeft: theme.spacing(3),
        display: "block",
    },
}));

export default Administrators;
