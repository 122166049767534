import React, { useState, useEffect } from "react";
import useAPI from "../../../hooks/api/useAPI";
import useAuth from "../../../hooks/auth/useAuth";
import { Container, Typography, CssBaseline, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Selector from "./SelectService";
import AdminDisplay from "./AdminDisplay";
import ServiceDisplay from "./ServiceDisplay";

const Dashboard = () => {
    const api = useAPI();
    const auth = useAuth();
    const classes = useStyles();
    const [facility, setFacility] = useState([]);
    const [administrator, setAdministrator] = useState([]);
    const [selectedService, setSelectedService] = useState("");
    const [selectedFacility, setSelectedFacility] = useState("");

    const getFacility = async () => {
        try {
            let response = await fetch(`${api.path}/facility/worker/`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setFacility(response);
        } catch (err) {
            alert("Error: " + err.message);
        }
    };

    const getAdministrator = async () => {
        try {
            let response = await fetch(`${api.path}/facility/administrator/`);
            if (!response.ok) {
                const statusCode = response.status;
                response = await response.json();
                throw new Error(`${statusCode} - ${response.message}`);
            }
            response = await response.json();
            setAdministrator(response);
        } catch (err) {
            alert("Error: " + err.message);
        }
    };

    const handleSelect = (facilityId, serviceId = "") => {
        if (serviceId) {
            setSelectedService(serviceId);
        } else {
            setSelectedService(facilityId);
        }
        setSelectedFacility(facilityId);
    };

    useEffect(() => {
        getFacility();
        getAdministrator();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="xl">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h2">
                    Facility Dashboard
                </Typography>
                <Grid container spacing={1}>
                    <Grid item lg={3} sm={12} className={classes.gridContainer}>
                        <Selector
                            facility={facility}
                            administrator={administrator}
                            selected={selectedService}
                            handleSelect={handleSelect}
                        />
                    </Grid>
                    <Grid item lg={9} sm={12} className={classes.gridContainer}>
                        {selectedService ? (
                            administrator.includes(selectedService) ? (
                                <AdminDisplay
                                    id={selectedService}
                                    getFacility={getFacility}
                                    facility={facility.filter(
                                        (item) => item._id === selectedService
                                    )}
                                />
                            ) : (
                                <ServiceDisplay
                                    id={selectedService}
                                    facilityId={selectedFacility}
                                    facility={facility.filter(
                                        (item) => item._id === selectedFacility
                                    )}
                                />
                            )
                        ) : (
                            ""
                        )}
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        width: "100%",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
}));

export default Dashboard;
