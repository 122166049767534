import React, { useState, useEffect, useCallback } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Typography,
    Button,
    Grid,
    TextField,
    Popover,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getTime } from "../../../functions/functions";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import useAPI from "./../../../hooks/api/useAPI";

const CheckedInGuests = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [reservations, setReservations] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [popover, setPopover] = useState("");

    const popoverOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setPopover(`popover-${id}`);
    };

    const popoverClose = () => {
        setAnchorEl(null);
        setPopover("");
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const setReservationList = useCallback(async () => {
        if (searchText !== "") {
            setReservations(
                props.reservations.filter(
                    (res) =>
                        res.user.profile.firstName !== null &&
                        (res.user.profile.firstName + res.user.profile.lastName)
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                )
            );
        } else {
            setReservations(props.reservations);
        }
    }, [searchText, props]);

    useEffect(() => {
        setReservationList();
    }, [setReservationList]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    // console.log(reservations)
    const currDTG = new Date();
    const resList = reservations.map((res) => {
        let overDue = false;
        const stopDTG = new Date(res.stop);
        const startDTG = new Date(res.start);
        const checkedInDTG = new Date(res.checkedIn);
        const stopTime = getTime(stopDTG);
        const startTime = getTime(startDTG);
        const checkedInTime = getTime(checkedInDTG);
        if (stopDTG < currDTG) {
            overDue = true;
        }
        return (
            <Accordion
                expanded={expanded === `panel${res._id}`}
                onChange={handleChange(`panel${res._id}`)}
                key={`panel${res._id}`}
                className={
                    overDue
                        ? classes.badStatusBackground
                        : classes.goodStatusBackground
                }
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${res._id}bh-content`}
                    id={`panel${res._id}bh-header`}
                >
                    <Typography
                        variant="body2"
                        className={classes.name}
                    >{`${res.user.profile.firstName} ${res.user.profile.lastName}`}</Typography>
                    {overDue ? (
                        <Typography
                            variant="body2"
                            className={clsx(classes.status, classes.badStatus)}
                        >
                            Over Due
                        </Typography>
                    ) : (
                        <Typography
                            variant="body2"
                            className={clsx(classes.status, classes.goodStatus)}
                        >
                            On-Time
                        </Typography>
                    )}
                </AccordionSummary>
                <AccordionDetails id={`panel${res._id}bh-details`}>
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Start</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {startTime}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Checked In</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {checkedInTime}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        <b>Stop</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body2">
                                        {stopTime}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {overDue ? (
                                        <>
                                            <Button
                                                variant="contained"
                                                className={classes.optionButton}
                                                onClick={(e) =>
                                                    popoverOpen(e, res._id)
                                                }
                                            >
                                                Options
                                            </Button>
                                            <Popover
                                                id={`popover-${res._id}`}
                                                open={
                                                    popover ===
                                                    `popover-${res._id}`
                                                }
                                                anchorEl={anchorEl}
                                                onClose={popoverClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "left",
                                                }}
                                            >
                                                <Container
                                                    className={
                                                        classes.optionContainer
                                                    }
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            props.handleCheckOut(
                                                                res._id
                                                            )
                                                        }
                                                        fullWidth
                                                    >
                                                        Check Out
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            props.handleLateCheckOut(
                                                                res._id
                                                            )
                                                        }
                                                        fullWidth
                                                    >
                                                        Late Check Out
                                                    </Button>
                                                </Container>
                                            </Popover>
                                        </>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            className={classes.optionButton}
                                            onClick={() =>
                                                props.handleCheckOut(res._id)
                                            }
                                        >
                                            Check Out
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        className={classes.optionButton}
                                        onClick={() =>
                                            props.handleOpenPopup(
                                                "viewProfile",
                                                res.user._id
                                            )
                                        }
                                    >
                                        Profile
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    });

    return (
        <Container className={classes.checkedInContainer}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <Typography variant="h4">Currently Checked In</Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <TextField
                        id="checkedInSearch"
                        type="text"
                        label="Search"
                        variant="outlined"
                        margin="dense"
                        size="small"
                        value={searchText}
                        onChange={(e) => handleSearch(e)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    {resList}
                </Grid>
            </Grid>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    checkedInContainer: {
        height: 432,
        padding: 0,
        overflowY: "auto",
        overflowX: "hidden",
    },
    name: {
        fontWeight: "bold",
        flexBasis: "65%",
        flexShrink: 0,
    },
    optionButton: {
        width: "100%",
    },
    badStatusText: {
        color: theme.palette.error.main,
    },
    goodStatusText: {
        color: theme.palette.success.main,
    },
    badStatusBackground: {
        backgroundColor: theme.palette.error.light,
    },
    goodStatusBackground: {
        // backgroundColor: theme.palette.success.light,
    },
    optionContainer: {
        display: "flex",
        flexDirection: "column",
    },
}));

export default CheckedInGuests;
